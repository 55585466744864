import Sucesso from '../../../assets/sucesso.svg'
import TitleFooter from '../../atoms/TitleFooter'
import TextFooter from '../../atoms/TextFooter'
import * as Styled from './style'

export default function AlertConfirm() {
  return (
    <>
      <Styled.Container>
        <div className="content">
          <img src={Sucesso} alt="" />
          <div className="texto">
            <TextFooter>cadastro realizado</TextFooter>
            <TitleFooter>com sucesso!</TitleFooter>
          </div>
        </div>
      </Styled.Container>
    </>
  )
}
