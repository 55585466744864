// components/pages/NovoCadastro.js
import React from 'react'
import * as Styled from './styles'

const NovoCadastro = ({ selectedCity }) => {
  return (
    <>
      <Styled.Container>
        <Styled.Content>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h2 style={{ marginBottom: '8px' }}>Cadastro: {getCityInfo(selectedCity).city}</h2>

            {getCityInfo(selectedCity).phone != '' && (
              <span style={{ marginBottom: '16px' }}>Whatsapp: <strong>{getCityInfo(selectedCity).phone}</strong></span>
            )}

            <iframe
              title="cadastro-empresas"
              width="560"
              height="900"
              src={`https://app.pipefy.com/public/form/${getPipefyFormId(
                selectedCity
              )}?embedded=true`}
              frameborder="0"
            ></iframe>
          </div>
        </Styled.Content>
      </Styled.Container>
    </>
  )
}

const getPipefyFormId = (city) => {
  switch (city) {
    case 'joao-pessoa':
      return 'XmLlnHBB'
    case 'campina-grande':
      return '11CMUy1S'
    case 'natal':
      return 'MImS6NLN'
    case 'maceio':
      return 'xe2n4l6E'
    case 'salvador' :
      return 'oTThzF4-'
    case 'outra':
      return 'XmLlnHBB'
  }
}

const getCityInfo = (city) => {
  switch (city) {
    case 'joao-pessoa':
      return {city: 'João Pessoa - PB', phone: '(83) 3113-6300'}
    case 'campina-grande':
      return {city: 'Campina Grande - PB', phone: '(83) 3113-6302'}
    case 'natal':
      return {city: 'Natal - RN', phone: '83) 3113-6301'}
    case 'maceio':
      return {city: 'Maceio - AL', phone: '(82) 98784-3059'}
    case 'salvador':
      return {city: 'Salvador - BA', phone: '(71) 99964‑4704'}
    case 'outra':
      return {city: 'Outras regiões', phone: ''}
    default:
      return 'Unknown'
  }
}

export default NovoCadastro
