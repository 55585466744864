import * as React from 'react'
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import MenuIcon from '@material-ui/icons/Menu'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import Box from '@material-ui/core/Box'

import { Link } from 'react-router-dom'

import * as Styled from './styles'

export default function MenuHamburguer() {
  const [state, setState] = React.useState({ left: false })

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const links = [
    {
      nome: 'Cadastre seu negocio',
      link: '/empresas'
    },
    {
      nome: 'Aplicativo para entregadores',
      link: '/motoboy'
    },
    {
      nome: 'Acessar painel',
      link: '/painel/login'
    }
  ]

  const list = (anchor) => (
    <Box
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {links.map((item, index) => (
          <Link to={item.link} key={index} style={{ textDecoration: 'none', color: 'black' }}>
            <ListItem button key={index}>
              <ListItemText primary={item.nome} />
              <ListItemIcon>
                {index % 3 === 0 ? <ArrowForwardIos /> : <ArrowForwardIos />}
              </ListItemIcon>
            </ListItem>
          </Link>
        ))}
      </List>
    </Box>
  )

  return (
    <>
      <Styled.Menu>
        {['left'].map((anchor) => (
          <React.Fragment key={anchor}>
            <Button onClick={toggleDrawer(anchor, true)}>
              <MenuIcon
                color="white"
                sx={{
                  color: 'var(--shape)',
                  fontSize: 35
                }}
              />
            </Button>
            <SwipeableDrawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
              onOpen={toggleDrawer(anchor, true)}
            >
              {list(anchor)}
            </SwipeableDrawer>
          </React.Fragment>
        ))}
      </Styled.Menu>
    </>
  )
}
