import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
    :root {
        --background: #E6DDEE;
        --background2: #E5E5E5;
        --shape: #FFFFFF;
        --purple: #7241A1;
        --purple2: #9876CC;
        --orange: #E7973E;
        --black: #3E324B;
    }

    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html {
        @media (max-width: 1080px) {
            font-size: 93.75%;
        }
        @media (max-width: 720px) {
            font-size: 87.5%;
        }
    }

    body, input, text-area, button {
        font-family: 'Raleway', sans-serif;
    }

    button {
        cursor: pointer;
    }

    .container {
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;

        @media (min-width: 768px) {
            width: 750px;
        }

        @media (min-width: 992px) {
            width: 970px;
        }

        @media (min-width: 1200px) {
            width: 1170px;
        }
    }

    .navLink {
        color: var(--shape);
        font-size: 0.875rem;
        font-weight: 400;
    }

    .row {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1rem;

        @media(max-width: 450px) {
            grid-template-columns: 2fr 2fr;
        }
    }

    .row2 {
        display: grid;
        grid-template-columns: 4fr 1fr 1fr;
        gap: 1rem;

        @media(max-width: 450px) {
            grid-template-columns: 2fr 1fr 1fr;
        }
    }

    .row3 {

        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        gap: 1rem;

        @media(max-width:450px) {
            grid-template-columns: 1fr;
        }
    }

    .react-modal-overlay {
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }
    .react-modal-content {
        width: 100%;
        max-width: 576px;
        background: var(--background);
        padding: 1.75rem;
        position: relative;
        border-radius: 0.25rem;
        margin-top: 3rem;
    }

    .button-close {
        position: absolute;
        border: 0;
        background: transparent;
        right: 0.25rem;
        top: 0.25rem;
        &:hover{
            filter: brightness(0.9);
        }
    }
`
