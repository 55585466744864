export async function getLocalidadeAtendidas() {
  const response = await fetch(`https://moovery.app/api/retorna_sub_regioes.php`)
  const data = await response.json()

  return await data
}

export async function verificaLatitudeLongitude({ cep }) {
  const url = `https://maps.google.com/maps/api/geocode/json?address=${cep}&sensor=false&key=AIzaSyBiYS5xE17c_5akUI0ojLavl_vGH3HsoIc`

  let latitude = ''
  let longitude = ''

  const response = await fetch(url)
  const data = await response.json()

  if (data.status === 'OK') {
    latitude = data.results[0].geometry.location.lat
    longitude = data.results[0].geometry.location.lng
  }

  const dadosLocalizacao = { latitude, longitude }

  return dadosLocalizacao
}
