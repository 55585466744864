import React from 'react'
import AlertWaiting from '../../molecules/AlertWaiting'
import AlertError from '../../molecules/AlertError'
import TitleRoxo from '../../atoms/TitleRoxo'
import Footer from '../../molecules/Footer'
import Menu from '../../molecules/Menu'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

import { getLocalidadeAtendidas } from '../../../services/api'

import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useState, useEffect } from 'react'

import * as Styled from './styles'

const nomeCliente = 'nomeCliente'
const CPFOUCNPJ = 'CPFOUCNPJ'
const open = 'open'

export default function Cadastro() {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const [waiting, setWaiting] = useState(false)
  const [errorEmpresaJaCadastrada, setEmpresaJaCadastrada] = useState(false)

  const history = useHistory()

  const onSubmit = (data) => {
    sessionStorage.setItem(open, 'true')
    localStorage.setItem(CPFOUCNPJ, data.cnpjOuCpf)
    localStorage.setItem(nomeCliente, data.nome)

    sendEmpresas(data.nome, data.email, data.telefone, data.cnpjOuCpf, data.subRegiaoId)
  }

  async function sendEmpresas(nome, email, telefone, cnpjOuCpf, subRegiaoId) {
    const body = new FormData()
    body.append('responsavel', nome)
    body.append('email', email.toLowerCase())
    body.append('telefone', telefone)
    body.append('cnpjOuCpf', cnpjOuCpf)
    body.append('subRegiaoId', subRegiaoId)

    setWaiting(true)
    const response = await fetch('https://moovery.app/api/empresa/cadastrar_empresa.php', {
      method: 'POST',
      body
    })
    const data = await response.json()

    if (response.status === 409) {
      setWaiting(false)
      setEmpresaJaCadastrada(true)
      setTimeout(() => {
        setEmpresaJaCadastrada(false)
      }, 2000)
    }

    if (!data.error && data.data.code === 201) {
      setTimeout(() => {
        setWaiting(false)
        history.push('./cadastroFinal')
      }, 2000)
    }

    if (data.error.code === 400) {
      setTimeout(() => {
        setWaiting(false)
        history.push('./cadastroFinal')
      }, 2000)
    }
  }

  const [phone, setPhone] = useState('')

  const maskPhone = (value) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})(\d+?)$/, '$1')
  }

  const [cpfOuCnpj, setCpfOucnpj] = useState('')

  const maskCPFouCNPJ = (value) => {
    value = value.replace(/\D/g, '')
    value = value.replace(/^(\d{2})(\d)/, '$1.$2')
    value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    value = value.replace(/\.(\d{3})(\d)/, '.$1/$2')
    value = value.replace(/(\d{4})(\d)/, '$1-$2')

    return value
  }

  const [result, setResult] = useState()
  useEffect(() => {
    getLocalidadeAtendidas().then(setResult)
  }, [])

  return (
    <>
      {waiting === true && <AlertWaiting />}
      {errorEmpresaJaCadastrada === true && (
        <AlertError>
          Empresa ja cadastrada! <br />
        </AlertError>
      )}
      <Menu />
      <Styled.Container>
        <Styled.ImgWrapper></Styled.ImgWrapper>
        <Styled.Content>
          <Styled.Chamada>
            <TitleRoxo>Cadastre sua empresa</TitleRoxo>
            <Styled.TitleDadoPessoal>
              <Styled.Subtitle>Primeiros passos</Styled.Subtitle>
              <Styled.Hr />
            </Styled.TitleDadoPessoal>
            <Box sx={{ flexGrow: 1 }}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                onKeyPress={(event) => {
                  event.key === 'Enter' && event.preventDefault()
                }}
              >
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Styled.InputField>
                      <label htmlFor="nome">Nome completo</label>
                      <input {...register('nome', { required: true })} placeholder="Nome" />
                      {errors.nome && (
                        <Styled.SpanError>
                          Preencha o campo ou verifique se está correto
                        </Styled.SpanError>
                      )}
                    </Styled.InputField>
                  </Grid>
                  <Grid item xs={12}>
                    <Styled.InputField>
                      <label htmlFor="email">Email</label>
                      <input
                        {...register('email', {
                          pattern:
                            /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                          required: true
                        })}
                        placeholder="email@email.com.br"
                      />
                      {errors.email && (
                        <Styled.SpanError>
                          Preencha o campo ou verifique se está correto
                        </Styled.SpanError>
                      )}
                    </Styled.InputField>
                  </Grid>
                  <Grid item xs={12}>
                    <Styled.InputField>
                      <label htmlFor="telefone">Whatsapp</label>
                      <input
                        {...register('telefone', {
                          // eslint-disable-next-line
                          pattern: /^(\(?\d{2}\)?\s)?(\d{4,5}\-\d{4})/,
                          required: true
                        })}
                        value={phone}
                        onChange={(event) => setPhone(maskPhone(event.target.value))}
                        placeholder="(00) 00000-0000"
                      />
                      {errors.telefone && (
                        <Styled.SpanError>
                          Preencha o campo ou verifique se está correto
                        </Styled.SpanError>
                      )}
                    </Styled.InputField>
                  </Grid>
                  <Grid item xs={6}>
                    {' '}
                    <Styled.InputField>
                      <label htmlFor="cpfOuCnpj">CNPJ</label>
                      <input
                        {...register('cnpjOuCpf', {
                          required: true,
                          pattern:
                            // eslint-disable-next-line
                            /^(\d{3}\.\d{3}\.\d{3}\-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$)/
                        })}
                        placeholder=""
                        maxLength={18}
                        value={cpfOuCnpj}
                        onChange={(event) => setCpfOucnpj(maskCPFouCNPJ(event.target.value))}
                      />
                      {errors.cnpjOuCpf && (
                        <Styled.SpanError>
                          Preencha o campo ou verifique se está correto
                        </Styled.SpanError>
                      )}
                    </Styled.InputField>
                  </Grid>
                  <Grid item xs={6}>
                    {' '}
                    <Styled.InputField>
                      <label htmlFor="subRegiaoId">Localidade</label>
                      <select {...register('subRegiaoId', { required: true })} name="subRegiaoId">
                        <option value=""></option>
                        {result?.map((localidade, index) => (
                          <option
                            value={localidade.id}
                            key={index}
                          >{`${localidade.cidade} - ${localidade.estado}`}</option>
                        ))}
                      </select>
                      {errors.subRegiaoId && (
                        <Styled.SpanError>
                          Preencha o campo ou verifique se está correto
                        </Styled.SpanError>
                      )}
                    </Styled.InputField>
                  </Grid>
                  <Grid item xs={12}>
                    <Styled.ButtonOrange type="submit" disabled={waiting}>
                      Cadastre-se
                    </Styled.ButtonOrange>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Styled.Chamada>
        </Styled.Content>
      </Styled.Container>
      <Footer />
    </>
  )
}
