import Menu from '../../molecules/Menu'
import Footer from '../../molecules/Footer'
import NossasIntregracoes from '../../molecules/NossasIntegracoes'
import * as React from 'react'
import TitleBanner from '../../atoms/TitleBanner'
import Computadores from '../../../assets/entregaConcluida.svg'
import ButtonPage from '../../atoms/ButtonPage'

import Grid from '@material-ui/core/Grid'

import * as Styled from './styles'

export default function PoliticaDePrivacidade() {
  function openWhatsApp() {
    let mensagem = `Olá, acabei de fazer o meu cadastro na moovery. Gostaria de mais informações sobre o funcionamento.`
    const params = new URLSearchParams({
      text: mensagem
    })

    window.open(`https://wa.me/558331136300?${params}`)
  }
  return (
    <>
      <Menu />
      <Styled.InitialView>
        <div className="container">
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={6}>
              <img src={Computadores} alt="" width={340} />
            </Grid>
            <Grid item xs={12} xl={6} md={6} sm={12}>
              <TitleBanner>
                Seu <Styled.Span>cadastro</Styled.Span> foi <Styled.Span> concluido </Styled.Span>
                com sucesso!
              </TitleBanner>
              <div className="subTitle">
                Aguarde o contato do nosso time comercial que cuidaremos do seu delivery para você!
                #VemPraMoovery
              </div>
              <ButtonPage link={openWhatsApp}>Fale conosco</ButtonPage>
            </Grid>
          </Grid>
        </div>
      </Styled.InitialView>
      <NossasIntregracoes />

      <Footer />
    </>
  )
}
