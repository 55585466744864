import imgCanelle from '../../../assets/logoCanelle.webp'
import imgChiquinhoSorvetes from '../../../assets/chiquinho-sorvetes-logo-69398D3BA4-seeklogo.com.png'
import imgTratoria from '../../../assets/logoTratoria.svg'
import imgHDO from '../../../assets/logoHDO.webp'
import imgPontoDoSuco from '../../../assets/pontodosucologo.png'
import imgRecantoDoPicui from '../../../assets/logoRecantoDoPicui.webp'

import * as Styled from './styles'

export default function empresasMotoboy() {
  return (
    <Styled.Content>
      <div className="container-fluid">
        <Styled.Empresas>
          <img src={imgTratoria} alt="logo trattoria" />
          <img src={imgCanelle} alt="logo canelle" />
          <img src={imgChiquinhoSorvetes} width={160} alt="logo chiquinho sorvetes" />
          <img src={imgHDO} alt="logo okane" />
          <img src={imgPontoDoSuco} width={160} alt="logo ponto do suco" />
          <img src={imgRecantoDoPicui} alt="logo recanto do picui" />
        </Styled.Empresas>
      </div>
    </Styled.Content>
  )
}
