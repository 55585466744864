import styled from 'styled-components'

export const Individual = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
`

export const Vantagens = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1.5rem;

  margin-top: 5rem;

  button {
    margin-top: 3.5rem;
  }
`

export const Content = styled.div`
  margin-top: 10rem;
  height: 100vh;

  @media (max-width: 590px) {
    height: auto;

    button {
      margin-bottom: 3rem;
    }
  }
`

export const ImgWrapper = styled.div`
  margin-top: 8rem;
  display: flex;
  gap: 3rem;
  position: absolute;
  right: 0;

  img {
    @media (max-width: 1216px) {
      display: none;
    }
  }
`
