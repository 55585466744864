import styled from 'styled-components'
import Capa from '../../../assets/capa1.webp'

export const Container = styled.div`
  display: flex;

  @media (max-width: 658px) {
    display: block;
  }
`

export const Content = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 768px) {
    width: 750px;
  }
  @media (min-width: 992px) {
    width: 970px;
  }
  @media (min-width: 1200px) {
    width: 1170px;
  }
`

export const Chamada = styled.div`
  margin-top: 4rem;
  margin-bottom: 5rem;
  display: flex;
  flex-direction: column;
  gap: 6rem;
`

export const Span = styled.span`
  color: var(--orange);
`

export const ImgWrapper = styled.div`
  position: relative;
  min-height: 150vh;
  min-width: 32.5vw;
  background-image: url(${Capa});
  background-repeat: repeat;

  @media (max-width: 658px) {
    display: none;
  }
`
export const TitleDadoPessoal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  gap: 2rem;

  hr {
    width: 100%;
  }
`

export const Subtitle = styled.div`
  font-size: 1rem;
  color: var(--purple);
  font-weight: 700;
`

export const Input = styled.input`
  width: 100%;
  background: var(--background2);
  border: 0;
  color: var(--black);
  font-weight: 700;
  padding: 12px 24px;

  border-radius: 5px;
  border: 0;
`

export const TitleDadoEmpresa = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  gap: 0.5rem;
`

export const Hr = styled.div`
  border: none;
  height: 1px;
  color: var(--purple);
  background-color: var(--purple);
  width: 100%;
`

export const Label = styled.div`
  font-size: 1rem;
  font-weight: 700;
  color: var(--black);
`

export const DadoEmpresa = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.75rem;
`

export const DadoPessoal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.75rem;
`

export const DadoTotal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4.5rem;
`

export const InputGroupEmpresa = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  form {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    p {
      font-size: 0.875rem;

      a {
        text-decoration: none;
        color: #1777f2;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`

export const A = styled.a`
  text-decoration: none;
  color: #1777f2;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export const InputField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  label {
    font-size: 1rem;
    font-weight: 700;
    color: var(--black);
  }

  input {
    width: 100%;
    background: var(--background2);
    border: 0;
    color: var(--black);
    font-weight: 700;
    padding: 12px 24px;

    border-radius: 5px;
    border: 0;
    outline-color: var(--purple);
  }
`

export const SpanError = styled.div`
  font-size: 0.8rem;
  color: red;
  font-weight: 600;
`
