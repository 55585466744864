import Error from '../../../assets/error.svg'
import TitleFooter from '../../atoms/TitleFooter'
import TextFooter from '../../atoms/TextFooter'
import * as Styled from './styles'

export default function AlertError({ children }) {
  return (
    <>
      <Styled.Container>
        <div className="content">
          <img src={Error} alt="" />
          <div className="texto">
            <TextFooter>Erro!</TextFooter>
            <TitleFooter>{children}</TitleFooter>
          </div>
        </div>
      </Styled.Container>
    </>
  )
}
