import ButtonOrange from '../../../components/atoms/ButtonOrange'
import TitleRoxo from '../../atoms/TitleRoxo'
import Text from '../../atoms/Text'

import iconSuporte from '../../../assets/iconSuporte.svg'
import iconDesign from '../../../assets/iconDesign.svg'
import imgCelular1 from '../../../assets/cell.webp'
import imgCelular2 from '../../../assets/celularCortado.webp'
import iconRotas from '../../../assets/iconRotas.svg'
import iconSeta from '../../../assets/setaDireta.svg'

import * as Styled from './styles'

export default function vantagensMotoboy() {
  function openPageAppMoovery() {
    window.open('https://play.google.com/store/apps/details?id=com.Moovery.app')
  }
  return (
    <>
      <Styled.ImgWrapper>
        <img src={imgCelular1} alt="" />
        <img src={imgCelular2} alt="" />
      </Styled.ImgWrapper>
      <Styled.Content>
        <div className="container">
          <div className="card">
            <TitleRoxo>
              Conheça nossas <br /> vantagens!
            </TitleRoxo>
            <Styled.Vantagens>
              <Styled.Individual>
                <img src={iconDesign} alt="" />
                <Text>Design pensado na facilidade</Text>
              </Styled.Individual>
              <Styled.Individual>
                <img src={iconRotas} alt="" />
                <Text>
                  Rotas de entregas com mais de 1 <br /> pedido
                </Text>
              </Styled.Individual>
              <Styled.Individual>
                <img src={iconSuporte} alt="" />
                <Text>Suporte humano em tempo real</Text>
              </Styled.Individual>
              <ButtonOrange link={openPageAppMoovery}>
                Saiba mais
                <img src={iconSeta} alt="" />
              </ButtonOrange>{' '}
            </Styled.Vantagens>
          </div>
        </div>
      </Styled.Content>
    </>
  )
}
