import iconEntregador from '../../../assets/iconEntregador.png'
import logoMoovery from '../../../assets/logoMoovery2.svg'
import iconEmpresa from '../../../assets/iconEmpresa.png'

import TitleFooter from '../../atoms/TitleFooter'
import TitleMedium from '../../atoms/TitleMedium'
import TextFooter from '../../atoms/TextFooter'
import Button from '../../atoms/ButtonInicial'

import { Link } from 'react-router-dom'

import * as Styled from './styles'

export default function Direcionador() {
  return (
    <>
      <Styled.Container>
        <div className="container">
          <Styled.Content>
            <Styled.Chamativo>
              <TitleMedium>
                Você é<span>?</span>
              </TitleMedium>
              <Styled.Direcionadores>
                <Link to="/empresas" style={{ textDecoration: 'none' }}>
                  <Button>
                    <img src={iconEmpresa} alt="" />
                    Empresa
                  </Button>
                </Link>
                <Link to="/motoboy" style={{ textDecoration: 'none' }}>
                  <Button>
                    <img src={iconEntregador} alt="" />
                    Entregador
                  </Button>
                </Link>
              </Styled.Direcionadores>
            </Styled.Chamativo>
            <Styled.Footer>
              <img src={logoMoovery} alt="" />
              <TitleFooter>Telefone: (83)3113-6300</TitleFooter>
              <TextFooter>contato@moovery.app</TextFooter>
            </Styled.Footer>
          </Styled.Content>
        </div>
      </Styled.Container>
    </>
  )
}
