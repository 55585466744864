import Menu from '../../molecules/Menu'
import TitleRoxo from '../../atoms/TitleRoxo'

import * as Styled from './style'
import { Grid } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import Footer from '../../molecules/Footer'
import GoToTop from '../../molecules/GoToTop'

export default function CadastroTrabalheConosco() {
  const [cidade, setCidade] = useState('')
  const [bairro, setBairro] = useState('')
  const [errorCep, setErrorCep] = useState(false)

  const [cep, setCep] = useState('')

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const onSubmit = (data) => {
    console.log(data)
  }

  async function getCep(value) {
    setCep(value)
    try {
      const response = await fetch(`https://viacep.com.br/ws/${value}/json/`)
      const data = await response.json()

      setCidade(data.localidade)
      setBairro(data.bairro)
    } catch (error) {
      setErrorCep(true)
      setTimeout(() => {
        setErrorCep(false)
        console.log(errorCep)
      }, 3000)
    }
  }

  return (
    <>
      <Menu />
      <div className="container" style={{ marginTop: '3rem', minHeight: '90vh' }}>
        <TitleRoxo>Cadastre-se</TitleRoxo>
        <Styled.TitleDadoPessoal>
          <h2>Dados Pessoais</h2>
          <hr />
        </Styled.TitleDadoPessoal>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} mt={3} rowSpacing={4}>
            <Grid item xs={6}>
              <Styled.InputField>
                <label htmlFor="nome">Nome</label>
                <input {...register('nome', { required: true })} />
                {errors.nome && (
                  <Styled.SpanError>Preencha o campo ou verifique se está correto</Styled.SpanError>
                )}
              </Styled.InputField>
            </Grid>
            <Grid item xs={2}>
              {' '}
              <Styled.InputField>
                <label htmlFor="idade">Idade</label>
                <input {...register('idade', { required: true })} />
                {errors.idade && (
                  <Styled.SpanError>Preencha o campo ou verifique se está correto</Styled.SpanError>
                )}
              </Styled.InputField>
            </Grid>
            <Grid item xs={4}>
              {' '}
              <Styled.InputField>
                <label htmlFor="sexo">Sexo</label>
                <select {...register('sexo', { required: true })} name="sexo">
                  <option style={{ color: 'red' }} value=""></option>
                  <option value="masculino">Masculino</option>
                  <option value="feminino">Feminino</option>
                  <option value="outro">Prefiro não informar</option>
                </select>
                {errors.sexo && (
                  <Styled.SpanError>Preencha o campo ou verifique se está correto</Styled.SpanError>
                )}
              </Styled.InputField>
            </Grid>
            <Grid item xs={4}>
              {' '}
              <Styled.InputField>
                <label htmlFor="cep">CEP</label>
                <input
                  {...register('cep', { required: true })}
                  placeholder=""
                  onBlur={(event) => getCep(event.target.value)}
                />
                {cep === '' && errors.cep && (
                  <Styled.SpanError>Preencha o campo ou verifique se está correto</Styled.SpanError>
                )}
              </Styled.InputField>
            </Grid>
            <Grid item xs={4}>
              {' '}
              <Styled.InputField>
                <label htmlFor="bairro">Bairro</label>
                <input
                  {...register('bairro')}
                  placeholder=""
                  onChange={(event) => {
                    setBairro(event.target.value)
                  }}
                  value={bairro}
                />
                {errors.cep && (
                  <Styled.SpanError>Preencha o campo ou verifique se está correto</Styled.SpanError>
                )}
              </Styled.InputField>
            </Grid>
            <Grid item xs={4}>
              {' '}
              <Styled.InputField>
                <label htmlFor="cidade">Cidade</label>
                <input
                  {...register('cidade')}
                  placeholder=""
                  onChange={(event) => {
                    setCidade(event.target.value)
                  }}
                  value={cidade}
                />
                {errors.cep && (
                  <Styled.SpanError>Preencha o campo ou verifique se está correto</Styled.SpanError>
                )}
              </Styled.InputField>
            </Grid>
            <Grid item xs={4}>
              {' '}
              <Styled.InputField>
                <label htmlFor="naturalidade">Naturalidade</label>
                <input {...register('naturalidade', { required: true })} />
                {errors.naturalidade && (
                  <Styled.SpanError>Preencha o campo ou verifique se está correto</Styled.SpanError>
                )}
              </Styled.InputField>
            </Grid>
            <Grid item xs={2}>
              {' '}
              <Styled.InputField>
                <label htmlFor="estadoCivil">Estado Civil</label>
                <select {...register('estadoCivil', { required: true })} name="estadoCivil">
                  <option value=""></option>
                  <option value="fundamental">Fundamental</option>
                  <option value="medio">Médio</option>
                  <option value="tecnico">Técnico</option>
                  <option value="superior">Superior</option>
                </select>
                {errors.estadoCivil && (
                  <Styled.SpanError>Preencha o campo ou verifique se está correto</Styled.SpanError>
                )}
              </Styled.InputField>
            </Grid>
            <Grid item xs={2}>
              {' '}
              <Styled.InputField>
                <label htmlFor="qdtFilhos">Qtd Filhos</label>
                <input {...register('qdtFilhos', { required: true })} />
                {errors.qdtFilhos && (
                  <Styled.SpanError>Preencha o campo ou verifique se está correto</Styled.SpanError>
                )}
              </Styled.InputField>
            </Grid>
            <Grid item xs={2}>
              {' '}
              <Styled.InputField>
                <label htmlFor="deficiencia">Deficiência</label>
                <select {...register('deficiencia', { required: true })} name="deficiencia">
                  <option value=""></option>
                  <option value="fundamental">Fundamental</option>
                  <option value="medio">Médio</option>
                  <option value="tecnico">Técnico</option>
                  <option value="superior">Superior</option>
                </select>
                {errors.deficiencia && (
                  <Styled.SpanError>Preencha o campo ou verifique se está correto</Styled.SpanError>
                )}
              </Styled.InputField>
            </Grid>
            <Grid item xs={2}>
              {' '}
              <Styled.InputField>
                <label htmlFor="veiculo">Possui veiculo?</label>
                <select {...register('veiculo', { required: true })} name="veiculo">
                  <option value=""></option>
                  <option value="Sim">Sim</option>
                  <option value="Não">Não</option>
                </select>
                {errors.veiculo && (
                  <Styled.SpanError>Preencha o campo ou verifique se está correto</Styled.SpanError>
                )}
              </Styled.InputField>
            </Grid>
            <Grid item xs={6}>
              <Styled.InputField>
                <label htmlFor="email">Email</label>
                <input
                  {...register('email', {
                    pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    required: true
                  })}
                />
                {errors.email && (
                  <Styled.SpanError>Preencha o campo ou verifique se está correto</Styled.SpanError>
                )}
              </Styled.InputField>
            </Grid>
            <Grid item xs={3}>
              {' '}
              <Styled.InputField>
                <label htmlFor="telefone">Telefone</label>
                <input {...register('telefone')} />
                {errors.telefone && (
                  <Styled.SpanError>Preencha o campo ou verifique se está correto</Styled.SpanError>
                )}
              </Styled.InputField>
            </Grid>
            <Grid item xs={3}>
              {' '}
              <Styled.InputField>
                <label htmlFor="whatsapp">Whatsapp</label>
                <input {...register('whatsapp')} />
                {errors.whatsapp && (
                  <Styled.SpanError>Preencha o campo ou verifique se está correto</Styled.SpanError>
                )}
              </Styled.InputField>
            </Grid>
          </Grid>

          <Styled.TitleDadoPessoal>
            <h2>Experiência acadêmica</h2>
            <hr />
          </Styled.TitleDadoPessoal>
          <Grid container spacing={3} mt={3} rowSpacing={4}>
            <Grid item xs={4}>
              <Styled.InputField>
                <label htmlFor="formacao">Formação</label>
                <select {...register('formacao', { required: true })} name="formacao">
                  <option value=""></option>
                  <option value="fundamental">Fundamental</option>
                  <option value="medio">Médio</option>
                  <option value="tecnico">Técnico</option>
                  <option value="superior">Superior</option>
                </select>
              </Styled.InputField>
            </Grid>
            <Grid item xs={4}>
              <Styled.InputField>
                <label htmlFor="grau">Grau</label>
                <select {...register('grau', { required: true })} name="grau">
                  <option value=""></option>
                  <option value="fundamental">Fundamental</option>
                  <option value="medio">Médio</option>
                  <option value="tecnico">Técnico</option>
                  <option value="superior">Superior</option>
                </select>
              </Styled.InputField>
            </Grid>{' '}
            <Grid item xs={4}>
              <Styled.InputField>
                <label htmlFor="status">Status</label>
                <select {...register('status', { required: true })} name="status">
                  <option value=""></option>
                  <option value="completo">Completo</option>
                  <option value="emAndamento">Em andamento</option>
                  <option value="incompleto">Incompleto</option>
                </select>
              </Styled.InputField>
            </Grid>
            <Grid item xs={6}>
              <Styled.InputField>
                <label htmlFor="curso">Curso</label>
                <input {...register('curso')} />
                {errors.curso && (
                  <Styled.SpanError>Preencha o campo ou verifique se está correto</Styled.SpanError>
                )}
              </Styled.InputField>
            </Grid>
            <Grid item xs={6}>
              <Styled.InputField>
                <label htmlFor="instituicao">Instituição</label>
                <input {...register('instituicao')} />
                {errors.instituicao && (
                  <Styled.SpanError>Preencha o campo ou verifique se está correto</Styled.SpanError>
                )}
              </Styled.InputField>
            </Grid>
            <Grid item xs={3}>
              <Styled.InputField>
                <label htmlFor="conclusao">Conclusão do curso</label>
                <select {...register('conclusao', { required: true })} name="conclusao">
                  <option value=""></option>
                  <option value="completo">Completo</option>
                  <option value="emAndamento">Em andamento</option>
                  <option value="incompleto">Incompleto</option>
                </select>
              </Styled.InputField>
            </Grid>
          </Grid>
          <Styled.TitleDadoPessoal>
            <h2>Experiência profissional</h2>
            <hr />
          </Styled.TitleDadoPessoal>
          <Grid container spacing={3} mt={3} rowSpacing={4}>
            <Grid item xs={6}>
              <Styled.InputField>
                <label htmlFor="empresa">Empresa</label>
                <input {...register('empresa')} />
                {errors.empresa && (
                  <Styled.SpanError>Preencha o campo ou verifique se está correto</Styled.SpanError>
                )}
              </Styled.InputField>
            </Grid>
            <Grid item xs={6}>
              <Styled.InputField>
                <label htmlFor="cargo">Cargo</label>
                <input {...register('cargo')} />
                {errors.cargo && (
                  <Styled.SpanError>Preencha o campo ou verifique se está correto</Styled.SpanError>
                )}
              </Styled.InputField>
            </Grid>
            <Grid item xs={3}>
              <Styled.InputField>
                <label htmlFor="inicio">Início</label>
                <select {...register('inicio', { required: true })} name="inicio">
                  <option value=""></option>
                  <option value="completo">Completo</option>
                  <option value="emAndamento">Em andamento</option>
                  <option value="incompleto">Incompleto</option>
                </select>
              </Styled.InputField>
            </Grid>
            <Grid item xs={3}>
              <Styled.InputField>
                <label htmlFor="inicio" style={{ color: 'white' }}>
                  g
                </label>
                <select {...register('inicio', { required: true })} name="inicio">
                  <option value=""></option>
                  <option value="completo">Completo</option>
                  <option value="emAndamento">Em andamento</option>
                  <option value="incompleto">Incompleto</option>
                </select>
              </Styled.InputField>
            </Grid>
            <Grid item xs={3}>
              <Styled.InputField>
                <label htmlFor="fim">Fim</label>
                <select {...register('fim', { required: true })} name="fim">
                  <option value=""></option>
                  <option value="completo">Completo</option>
                  <option value="emAndamento">Em andamento</option>
                  <option value="incompleto">Incompleto</option>
                </select>
              </Styled.InputField>
            </Grid>
            <Grid item xs={3}>
              <Styled.InputField>
                <label htmlFor="inicio" style={{ color: 'white' }}>
                  g
                </label>
                <select {...register('inicio', { required: true })} name="inicio">
                  <option value=""></option>
                  <option value="completo">Completo</option>
                  <option value="emAndamento">Em andamento</option>
                  <option value="incompleto">Incompleto</option>
                </select>
              </Styled.InputField>
            </Grid>
            <Grid item xs={12}>
              <Styled.InputField>
                <label htmlFor="descricaoDasAtividades">Descrição das atividades</label>
                <textarea name="" id="" cols="" rows="5"></textarea>
              </Styled.InputField>
            </Grid>
            <Grid item xs={12}>
              <Styled.ButtonOrange type="submit">Cadastre-se</Styled.ButtonOrange>
            </Grid>
          </Grid>
        </form>
      </div>
      <GoToTop />
      <Footer />
    </>
  )
}
