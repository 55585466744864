import styled from 'styled-components'

export const Title = styled.div`
  font-size: 2.5rem;
  color: var(--black);
  font-weight: 900;
`

export const TitleDadoPessoal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  gap: 2rem;
  margin-top: 3rem;

  hr {
    width: 100%;
    border: none;
    height: 1px;
    color: var(--purple);
    background-color: var(--purple);
    width: 100%;
  }

  h2 {
    font-size: 1rem;
    color: var(--purple);
    font-weight: 700;
  }
`

export const InputField = styled.div`
  label {
    font-size: 1rem;
    font-weight: 700;
    color: var(--black);
  }

  input,
  select,
  textarea {
    width: 100%;
    background: var(--background2);
    border: 0;
    color: var(--black);
    font-weight: 700;
    padding: 15px 20px;

    border-radius: 5px;
    border: 0;
    outline-color: var(--purple);
    margin-top: 0.5rem;
  }

  option {
    color: var(--black);
    font-weight: 700;
  }
`

export const SpanError = styled.div`
  font-size: 0.8rem;
  color: red;
  font-weight: 600;
`

export const ButtonOrange = styled.button`
  background-color: var(--orange);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.75rem;
  padding: 1rem 0.75rem;
  border: none;
  width: 100%;
  margin-bottom: 3rem;

  color: var(--shape);
  font-weight: 700;
  font-size: 1rem;
  border-radius: 0.25rem;
  margin-top: 1rem;

  &:hover {
    background-color: #cc7a29;
    transition: 0.2s;
  }
`
