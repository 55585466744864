import iconSeta from '../../../assets/setaDireta.svg'
import imgMotoboy from '../../../assets/motoboy.webp'
import TitleBanner from '../../atoms/TitleBanner'
import ButtonPage from '../../atoms/ButtonPage'
import Subtitle from '../../atoms/Subtitle'

import * as Styled from './styles'

export default function Motoboy() {
  function openPageAppMoovery() {
    window.open('https://play.google.com/store/apps/details?id=com.Moovery.app')
  }
  return (
    <Styled.Container>
      <Styled.Content>
        <Styled.Chamada>
          <TitleBanner>
            Conectamos{' '}
            <Styled.Span>
              você
              <br /> motoboy
            </Styled.Span>{' '}
            aos nossos
            <br />
            <Styled.Span>estabelecimentos</Styled.Span>
          </TitleBanner>
          <Subtitle>Baixe nosso app!</Subtitle>
          <ButtonPage link={openPageAppMoovery}>
            Saiba mais!
            <img src={iconSeta} alt="" />
          </ButtonPage>
        </Styled.Chamada>
        <Styled.Img src={imgMotoboy} alt="" />
      </Styled.Content>
    </Styled.Container>
  )
}
