import imgIfood from '../../../assets/ifood.webp'
import imgNemo from '../../../assets/nemo.webp'
import imgDeliveryDireto from '../../../assets/deliveryDireto.webp'
import imgMeuCardapio from '../../../assets/meuCardapio.webp'
import imgMenew from '../../../assets/menew.webp'

import * as Styled from './styles'

export default function NossasIntegracoes() {
  return (
    <>
      <Styled.ElementVantagensWhite>
        <div className="imgsGroup">
          <div className="imgLine">
            <img src={imgDeliveryDireto} alt="" />
            <img src={imgMeuCardapio} alt="" />
            <img src={imgNemo} alt="" />
          </div>
          <div className="imgLine">
            <img src={imgMenew} alt="" />
            <img src={imgIfood} alt="" />
          </div>
        </div>
        <div className="descricaoGroup">
          <div className="titleGroup">
            <Styled.Title>Integrações</Styled.Title>
            <Styled.SubTitle>Conheça nossas integrações!</Styled.SubTitle>
          </div>
          <p style={{ textAlign: 'right' }}>
            Integramos com as principais plataformas de logística do <br /> Brasil! Ifood, Menew,
            Delivery Direto, Nemo e Meu cardápio.
          </p>
        </div>
      </Styled.ElementVantagensWhite>
    </>
  )
}
