import styled from 'styled-components'

export const Title = styled.div`
  font-size: 2.25rem;
  font-weight: 900;
  color: var(--purple);
`

export const Container = styled.div``

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`
export const Hr = styled.hr`
  border: 1px solid var(--purple2);
  width: 391px;
`
export const Text = styled.div`
  font-size: 1.125rem;
  color: var(--black);
  font-weight: 400;
`

export const Individual = styled.div`
  display: flex;
  gap: 1.875rem;
  align-items: center;
`
export const Vantagens = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

export const TitleGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  margin-left: 1rem;
`
