import styled from 'styled-components'
import Capa from '../../../assets/capa1.webp'

export const Container = styled.div`
  display: flex;

  @media (max-width: 658px) {
    display: block;
  }
`

export const Content = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 4rem;

  display: flex;
  justify-content: center;

  @media (min-width: 768px) {
    width: 750px;
  }
  @media (min-width: 992px) {
    width: 970px;
  }
  @media (min-width: 1200px) {
    width: 1170px;
  }
  h2 {
    margin-bottom: 20px;
  }
`

export const Chamada = styled.div`
  margin-top: 4rem;
  margin-bottom: 5rem;
  display: flex;
  flex-direction: column;
  gap: 3.75rem;
`

export const Span = styled.span`
  color: var(--orange);
`

export const ImgWrapper = styled.div`
  position: relative;
  min-height: 150vh;
  min-width: 32.5vw;
  background-image: url(${Capa});
  background-repeat: repeat;

  @media (max-width: 658px) {
    display: none;
  }
`
export const TitleDadoPessoal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  gap: 2rem;

  hr {
    width: 100%;
  }
`

export const Subtitle = styled.div`
  font-size: 1rem;
  color: var(--purple);
  font-weight: 700;
`

export const Input = styled.input`
  width: 100%;
  background: var(--background2);
  border: 0;
  color: var(--black);
  font-weight: 700;
  padding: 12px 24px;

  border-radius: 5px;
  border: 0;
`

export const TitleDadoEmpresa = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  gap: 0.5rem;
`

export const Hr = styled.div`
  border: none;
  height: 1px;
  color: var(--purple);
  background-color: var(--purple);
  width: 100%;
`

export const Label = styled.div`
  font-size: 1rem;
  font-weight: 700;
  color: var(--black);
`

export const DadoEmpresa = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.75rem;
`

export const DadoPessoal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.75rem;
`

export const DadoTotal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4.5rem;
`

export const InputGroupEmpresa = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
`

export const InputField = styled.div`
  label {
    font-size: 1rem;
    font-weight: 700;
    color: var(--black);
  }

  input,
  select {
    width: 100%;
    background: var(--background2);
    border: 0;
    color: var(--black);
    font-weight: 700;
    padding: 12px 24px;

    border-radius: 5px;
    border: 0;
    outline-color: var(--purple);
  }
`

export const SpanError = styled.div`
  font-size: 0.8rem;
  color: red;
  font-weight: 600;
`

export const ButtonOrange = styled.button`
  background-color: var(--orange);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.75rem;
  padding: 0.5rem 0.75rem;
  border: none;
  width: 100%;

  color: var(--shape);
  font-weight: 700;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  margin-top: 1rem;

  &:hover {
    background-color: #cc7a29;
    transition: 0.2s;
  }
`
