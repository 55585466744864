import styled from 'styled-components'

export const ElementVantagensWhite = styled.div`
  .titleGroupMoovery {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
  }

  .descricaoGroupMoovery {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .motoMoovery {
    max-width: 25rem;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 85vh;

  @media (max-width: 983px) {
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column-reverse;
    gap: 5rem;
  }

  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 768px) {
    width: 750px;
  }

  @media (min-width: 992px) {
    width: 970px;
  }

  @media (min-width: 1200px) {
    width: 1170px;
  }
`
export const Title = styled.div`
  font-size: 4rem;
  font-weight: 900;
  color: var(--purple);
  @media (max-width: 655px) {
    font-size: 3rem;
  }
`

export const SubTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--orange);
`

export const BackgroundPurple = styled.div`
  background-color: var(--shape);
`
