import styled from "styled-components";

export const Container = styled.div`
    background-color: #46A351;
    position: fixed;
    bottom: 0.5rem;
    right: 0.5rem;
    z-index: 1;

    padding: 2rem;
    border-radius: 1.25rem;

    .content {
        display: flex;
        align-items: center;
        gap: 1.5rem;
        margin-right: 3rem;

        img {
            max-width: 3rem;
        }
    }
`