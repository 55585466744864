import styled from 'styled-components'

export const Title = styled.div`
  font-size: 3.75rem;
  font-weight: 900;
  color: var(--shape);

  @media (max-width: 447px) {
    font-size: 2.5rem;
  }
`
