import MenuHamburguer from '../../molecules/MenuHamburguer'
import logoMoovery from '../../../assets/logoMoovery2.svg'
import ButtonMenu from '../../atoms/ButtonMenu'

import { NavLink } from 'react-router-dom'

import * as Styled from './styles'

export default function Menu() {
  function openPageAppMoovery() {
    window.open('https://moovery.app/painel/login')
  }
  return (
    <Styled.Content>
      <div className="container">
        <Styled.Header>
          <a href="/">
            <img src={logoMoovery} alt="" />
          </a>
          <Styled.Options>
            <NavLink
              to="/motoboy"
              className="navLink"
              style={(isActive) =>
                isActive
                  ? {
                      color: 'var(--shape)',
                      fontSize: '0.875rem',
                      fontWeight: '700',
                      textDecoration: 'none'
                    }
                  : {
                      color: 'var(--shape)',
                      fontSize: '0.875rem',
                      fontWeight: '400',
                      textDecoration: 'none'
                    }
              }
            >
              Aplicativo para entregadores
            </NavLink>
            <NavLink
              to="/empresas"
              className="navLink"
              style={(isActive) =>
                isActive
                  ? {
                      color: 'var(--shape)',
                      fontSize: '0.875rem',
                      fontWeight: '700',
                      textDecoration: 'none'
                    }
                  : {
                      color: 'var(--shape)',
                      fontSize: '0.875rem',
                      fontWeight: '400',
                      textDecoration: 'none'
                    }
              }
            >
              Plataforma para empresas
            </NavLink>
            <ButtonMenu link={openPageAppMoovery}>Acessar painel</ButtonMenu>
          </Styled.Options>
          <MenuHamburguer />
        </Styled.Header>
      </div>
    </Styled.Content>
  )
}
