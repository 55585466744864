import styled from 'styled-components'

export const Content = styled.div`
  background-color: var(--background);
  height: auto;
`

export const Empresas = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  gap: 2rem;
  flex-wrap: wrap;

  img {
    padding: 2rem 0rem 2rem 0rem;
    mix-blend-mode: darken;
  }
`
