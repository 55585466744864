import styled from 'styled-components'
import Capa from '../../../assets/capa1.webp'

export const Container = styled.div`
  background-image: url(${Capa});
  background-repeat: repeat;
  min-height: 100vh;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;

  span {
    color: var(--orange);
  }
`

export const Direcionadores = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
  margin-top: 3.125rem;
  margin-bottom: 8rem;

  button {
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--shape);
    gap: 1.75rem;
    padding: 1rem 3rem;

    color: var(--shape);
    font-weight: 900;
    font-size: 1.25rem;
    border-radius: 0.25rem;
    max-height: 4.5rem;
    width: 18rem;

    &:hover {
      background-color: #cc7a29;
      transition: 0.1s;
      border: none;
    }
  }
`

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 3rem;
  color: var(--shape);
  gap: 0.25rem;
`

export const ImgWrapper = styled.div`
  position: absolute;
`

export const Chamativo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 10rem;
`
