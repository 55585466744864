import * as Styled from './styles'
import Bola from '../../../assets/Bola.svg'

export default function VantagensEmpresa() {
  return (
    <Styled.Container>
      <Styled.Content>
        <Styled.Vantagens>
          <Styled.Individual>
            <img src={Bola} alt="" />
            <Styled.Text>
              Aumente suas vendas e tenha mais <br /> tempo livre para dedicar a outras atividades.
            </Styled.Text>
          </Styled.Individual>
          <Styled.Individual>
            <img src={Bola} alt="" />
            <Styled.Text>
              Com a Moovery você vende em <br /> qualquer canal, a gente entrega e você <br /> ainda
              economiza com isso.
            </Styled.Text>
          </Styled.Individual>
          <Styled.Individual>
            <img src={Bola} alt="" />
            <Styled.Text>
              Não perca clientes! Tenha agilidade, <br /> gerenciamento e automação nas suas <br />{' '}
              entregas.
            </Styled.Text>
          </Styled.Individual>
        </Styled.Vantagens>
      </Styled.Content>
    </Styled.Container>
  )
}
