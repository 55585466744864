import styled from 'styled-components'

export const Button = styled.button`
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: var(--shape);
  gap: 1.75rem;
  padding: 1rem 3rem;

  color: var(--shape);
  font-weight: 900;
  font-size: 1.25rem;
  max-height: 4.5rem;
  border-radius: 0.25rem;
`
