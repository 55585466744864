import styled from 'styled-components'

export const Container = styled.div`
  .imgLine {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top:.6rem;

    img {
      max-width: 125px;
      border-radius: 50%;
    }
    @media (max-width: 983px) {
      img {
        max-width: 115px;
      }
    }

    @media (max-width: 397px) {
      img {
        display: none;
      }
    }
  }

  .imgsGroup {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .titleGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.25rem;
  }

  .descricaoGroup {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
`

export const Title = styled.div`
  font-size: 4rem;
  font-weight: 900;
  color: var(--purple);
  @media (max-width: 655px) {
    font-size: 3rem;
  }
`

export const SubTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--orange);
`

export const ElementVantagens = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 983px) {
    flex-wrap: wrap;
    justify-content: center;
  }
  min-height: 85vh;

  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 768px) {
    width: 750px;
  }

  @media (min-width: 992px) {
    width: 970px;
  }

  @media (min-width: 1200px) {
    width: 1170px;
  }
`

export const ElementVantagensWhite = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 85vh;
  background-color: var(--background);

  @media (max-width: 983px) {
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column-reverse;
    gap: 5rem;
  }

  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 768px) {
    width: 750px;
  }

  @media (min-width: 992px) {
    width: 970px;
  }

  @media (min-width: 1200px) {
    width: 1170px;
  }
`

export const TitleGroupLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
`

export const ImgMapa = styled.img`
  max-width: 300px;
`

export const BackgroundPurple = styled.div`
  background-color: var(--background);
`
