import VantagensMotoboy from '../../molecules/VantagensMotoboy'
import EmpresasMoovery from '../../molecules/EmpresasMoovery'
import EntreEmContato from '../EntreEmContato'
import Motoboy from '../../molecules/Motoboy'
import Footer from '../../molecules/Footer'
import Menu from '../../molecules/Menu'

export default function PaginaMotoboy() {
  return (
    <>
      <Menu />
      <Motoboy />
      <EmpresasMoovery />
      <VantagensMotoboy />
      <EntreEmContato />
      <Footer />
    </>
  )
}
