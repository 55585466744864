import Empresa from '../../molecules/Empresa'
import Footer from '../../molecules/Footer'
import Menu from '../../molecules/Menu'
import VantagensParaEmpresa from '../VantagensParaEmpresa'
import EmpresasMoovery from '../../molecules/EmpresasMoovery'

export default function OrganismoEmpresa() {
  return (
    <>
      <Menu />
      <Empresa />
      <EmpresasMoovery />
      <VantagensParaEmpresa />
      <Footer />
    </>
  )
}
