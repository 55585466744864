import styled from 'styled-components'

export const Content = styled.div`
  .buttonsGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 3rem;
  }
`

export const Title = styled.div`
  font-size: 2rem;
  font-weight: 900;
  color: var(--purple);

  @media (max-width: 447px) {
    font-size: 2.5rem;
  }
`

export const Span = styled.span`
  color: var(--orange);
`

export const ButtonOrange = styled.button`
  background-color: var(--orange);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.75rem;
  padding: 0.5rem 0.75rem;
  border: none;

  color: var(--shape);
  font-weight: 700;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  margin-top: 1rem;

  &:hover {
    background-color: #cc7a29;
    transition: 0.2s;
  }
`
