import styled from 'styled-components'

export const ButtonOrange = styled.button`
  background-color: var(--orange);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.75rem;
  padding: 0.75rem 0.875rem;
  border: none;

  color: var(--shape);
  font-weight: 700;
  font-size: 1rem;
  border-radius: 0.25rem;
  margin-top: 1rem;

  &:hover {
    background-color: #cc7a29;
    transition: 0.2s;
  }
`
