import styled from 'styled-components'

export const InitialView = styled.div`
  background-color: var(--purple);
  display: flex;
  align-items: center;
  min-height: 70vh;

  .subTitle {
    font-size: 1rem;
    color: var(--shape);
    font-weight: 400;
    margin-top: 2rem;
    margin-bottom: 2.25rem;
  }

  img {
    margin-left: 3rem;
    @media (max-width: 920px) {
      display: none;
    }
  }
`

export const Text = styled.div`
  font-size: 1rem;
  color: var(--black);
  font-weight: 400;
  line-height: 1.75rem;
  margin: 2rem 0rem;
  text-align: justify;
`

export const Span = styled.span`
  color: var(--orange);
`
