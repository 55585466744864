import Menu from '../../molecules/Menu'
import { Typography } from '@material-ui/core'
import Footer from '../../molecules/Footer'
import * as React from 'react'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import TitleBanner from '../../atoms/TitleBanner'

import * as Styled from './styles'

export default function PoliticaDePrivacidade() {
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <>
      <Menu />
      <Styled.InitialView>
        <div className="container">
          <div className="campoTexto">
            <TitleBanner>
              Termos de uso
              <br /> entregador{' '}
            </TitleBanner>
            <div className="subTitle">João Pessoa/PB, 12 de janeiro de 2022.</div>
          </div>
        </div>
      </Styled.InitialView>
      <div className="container">
        <Styled.Text>
          Pelo presente instrumento particular, doravante denominado de{' '}
          <strong>“TERMOS E CONDIÇÕES”</strong>, a{' '}
          <strong>MOOVERY SERVIÇOS DE INTERMEDIAÇÃO DE NEGÓCIOS LIMITADA</strong>, pessoa jurídica
          de direito privado inscrita no CNPJ sob o nº 34.778.892/0001-78, com sede estabelecida na
          rua Barão de Passagem, nº 1.534 do bairro da Torre, CEP 58.040-520, do município de João
          Pessoa/PB, doravante denominada de <strong>“MOOVERY”</strong> e o
          <strong>“ENTREGADOR”</strong>, toda e qualquer pessoa, através de veículo motorizado ou
          não, interessada em prestar serviço de frete, entrega ou delivery, de forma autônoma,
          qualificado de acordo com os seus dados informados no momento de seu cadastro na
          <strong>“PLATAFORMA”</strong>, resolvem ajustar as seguintes cláusulas e condições:
        </Styled.Text>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            style={{ padding: '1rem' }}
          >
            <Typography fontWeight="bold">1. Objeto</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              1.1. O objeto destes TERMOS E CONDIÇÕES é a regulamentação do cadastro, validação e
              uso do ENTREGADOR da PLATAFORMA para que ele possa ofertar a prestação de seu serviço
              de frete, entrega ou delivery aos estabelecimentos comerciais também ora cadastrados.
              <br />
              <br />
              1.1.1. Entende-se por PLATAFORMA a solução digital criada pela MOOVERY e resultado do
              conjunto de softwares por ela desenvolvidos, apresentado no modelo de aplicativo móvel
              (tanto para o sistema operacional Android), em componentes e páginas de Internet
              (baseado na Web) e/ou em programas para computadores (para os sistemas operacionais
              Windows), bem como a sua codificação e APIs (Application Programming Interface –
              Interface de programação de aplicações), englobando todo o conteúdo dos arquivos
              fornecidos, seja através de um meio tangível, depositado em equipamentos de mídia
              física, através de downloads, ou por compartilhamento de armazenagem digital (nuvens
              virtuais), incluindo, mas não se limitando aos materiais escritos, explicativos e
              comentados, memorial descritivo, especificações funcionais-técnicas, código-fonte e
              scripts de seus upgrades, modificações, atualizações, adições e cópias.
              <br />
              <br /> 1.1.2. A PLATAFORMA está disponível gratuitamente nas lojas virtuais de
              aplicativos Play Store para celulares Android.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} mb={5}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
            style={{ padding: '1rem' }}
          >
            <Typography fontWeight="bold">
              2. Requisitos, Documentação, Cadastro e Validação
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              2.1. O ENTREGADOR que deseja prestar seu serviço de frete, entrega ou delivery através
              da intermediação da PLATAFORMA deve informar todos os seus dados necessários (nome
              completo, números de inscrição no CPF e de RG, data de nascimento, número de celular
              principal, endereço de correspondência eletrônica – e-mail, conta bancária e nome e
              contato de emergência) à perfeita conclusão do cadastro e posterior validação,
              inclusive apresentando os seguintes documentos, através de sua captura fotográfica
              feita diretamente no aplicativo ou por anexação do arquivo digital.
              <br />
              <br /> a) Carteira Nacional de Habilitação (CNH) das categorias “A” ou “B”, sobretudo
              com autorização especial para exercício de atividade remunerada, ou Cédula de
              Identidade do Registro Geral (RG) e Comprovante de inscrição no Cadastro de Pessoas
              Físicas (CPF), caso opte por prestar seu serviço de frete, entrega ou delivery com
              bicicleta;
              <br />
              <br />
              b) Certificado de Registro e Licenciamento do Veículo (CRLV ou CRLV-e) atualizado;
              <br />
              <br />
              c) Comprovante de residência atualizado;
              <br />
              <br />
              d) Fotografia para visualização de seu perfil no aplicativo;
              <br />
              <br />
              2.2. O ENTREGADOR que deseja prestar seu serviço de frete, entrega ou delivery através
              da intermediação da PLATAFORMA também deve possuir os seguintes equipamentos:
              navegador web utilizado; 5) informações de URL, de conexão de rede e do provedor;
              <br />
              <br /> a) Celular com plano de Internet 4g móvel contratado e sistema operacional
              Android 5.0 ou superior;
              <br />
              <br />
              b) Caixa térmica (“bag”);
              <br />
              <br />
              2.3. O ENTREGADOR aqui declara estar ciente de que toda a documentação apresentada
              será analisada e aprovada previamente pela MOOVERY, que poderá ou não validar o seu
              cadastro, de acordo com os critérios internos de aprovação ora adotados.
              <br />
              <br />
              2.3.1. O ENTREGADOR apenas poderá oferecer o seu serviço de frete, entrega ou delivery
              através da intermediação da PLATAFORMA após ter seu cadastro validado.
              <br />
              <br />
              2.3.2. O ENTREGADOR se compromete com a veracidade e autenticidade de todos os
              documentos anexados na PLATAFORMA, assim como por todas as informações cadastrais, sob
              pena de responder civil e criminalmente.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
            style={{ padding: '1rem' }}
          >
            <Typography fontWeight="bold">
              3. Uso, Dados, Funcionamento e Características da Plataforma
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              3.1. O ENTREGADOR declara estar ciente que estes TERMOS E CONDIÇÕES representam, entre
              outras coisas, uma licença de utilização da plataforma dos moldes EULA – End User
              License Agreement, ou seja, um acordo de licença de usuário final em que não há, em
              nenhuma hipótese, a transmissão do direito de uso provisório do aplicativo obtido pelo
              ENTREGADOR após a validação do cadastro a quaisquer terceiros, bem como não garante
              acesso, em circunstância alguma, à sua codificação, APIs, código-fonte e scripts de
              seus upgrades, modificações, atualizações, adições e cópias, mas apenas a utilização
              da PLATAFORMA para prestar seu serviço de frete, entrega ou delivery.
              <br />
              <br />
              3.2. Para perfeito uso da PLATAFORMA e recebimento de chamados dos estabelecimentos
              comerciais tomadores do serviço de frete, entrega ou delivery do ENTREGADOR é
              necessário sempre estar com o celular ligado, conectado aos dados móveis contratados
              de sua operadora e com login efetuado no aplicativo.
              <br />
              <br />
              3.3. Para perfeito funcionamento da PLATAFORMA é necessário que o ENTREGADOR conceda,
              em seu celular, as seguintes permissões de acesso pelo aplicativo:
              <br />
              <br />
              a) Geolocalização fixa;
              <br />
              <br />
              b) Geolocalização em tempo real;
              <br />
              <br />
              c) Sobreposição de tela;
              <br />
              <br />
              d) Câmera;
              <br />
              <br />
              e) Arquivos de mídia;
              <br />
              <br />
              f) Microfone;
              <br />
              <br />
              3.4. O ENTREGADOR, desde já, declara estar ciente de que a MOOVERY pode transmitir,
              disponibilizar ou compartilhar os dados de cadastro elencados na cláusula 2.1 e os
              colhidos pelo aplicativo após a concessão das permissões de acesso ao celular
              mencionada na cláusula 3.3 com os estabelecimentos comerciais ora cadastrados na
              PLATAFORMA, para perfeita intermediação da prestação do serviço de frete, entrega ou
              delivery efetuado.
              <br />
              <br />
              3.4.1. O ENTREGADOR, desde já, declara estar ciente de que a MOOVERY coleta, armazena
              e trata os dados de cadastro elencados na cláusula 2.1 e os colhidos pelo aplicativo
              após a concessão das permissões de acesso ao celular mencionada na cláusula 3.3 para
              perfeita gestão da PLATAFORMA, com aperfeiçoamento do aplicativo e otimização da
              intermediação da prestação do serviço de frete, entrega ou delivery, com a geração de
              relatórios administrativos e organizacionais da empresa; identificação de seu
              posicionamento geográfico, de suas preferências de horário e de rotas, de seu tempo
              médio de entrega; bem como para receber e efetuar repasses, depósitos e transferências
              financeiras relativos às comissões pagas pelos estabelecimentos comerciais tomadores
              do serviço do ENTREGADOR; para analisar possíveis casos de fraude e/ou inconsistências
              cadastrais e para, enfim, promover pesquisas de satisfação.
              <br />
              <br />
              3.4.2. O ENTREGADOR, desde já, concorda com a divulgação de notas e comentários sobre
              a prestação de seu serviço de frete, entrega ou delivery na PLATAFORMA e declara estar
              ciente que toda coleta, armazenamento, tratamento, transmissão, disponibilização ou
              compartilhamento de dados das cláusulas 3.4 e 3.4.1 estão em compasso com a Lei nº
              13.709/2018 (Lei Geral de Proteção de Dados) e assevera que a concordância com estes
              TERMOS E CONDIÇÕES no momento de seu cadastro, representada pela marcação positiva do
              checkbox e avanço de tela do aplicativo, configura o seu livre, espontâneo e expresso
              consentimento com as finalidades ora indicadas.
              <br />
              <br />
              3.5. Os chamados e pedidos de frete, entrega ou delivery feitos pelos estabelecimentos
              comerciais ora cadastrados são feitos exclusivamente por eles próprios, sem qualquer
              interferência da MOOVERY, que apenas garante o funcionamento da PLATAFORMA, seguindo
              diretrizes básicas de gestão, para ordenar a prestação de serviço pelo ENTREGADOR de
              forma regular, uniforme, imparcial e meritocrática.
              <br />
              <br />
              3.5.1. O ENTREGADOR consegue se agendar para prestar o frete, entrega ou delivery para
              os estabelecimentos comerciais que necessitam da tomada do serviço em tempo real (dia
              “zero”) e também com antecedência em 01 (um) dia futuro (dia “um”).
              <br />
              <br />
              3.5.1.1. As únicas possibilidades do ENTREGADOR não conseguir se agendar para prestar
              o frete, entrega ou delivery de determinado tomador do serviço nos dias “zero” e “um”
              são apenas as dele se encontrar bloqueado, não pela MOOVERY, mas pelo próprio
              estabelecimento comercial ou se o autoagendamento estiver desligado para todos na
              PLATAFORMA, para realização de alguma manutenção ou atualização.
              <br />
              <br />
              3.5.1.2. A prioridade e visibilidade de agendamentos com antecedência em 02 (dois)
              dias futuros (dia “dois”) será destinada ao ENTREGADOR que tiver uma taxa percentual
              de autocancelamento abaixo dos 20% (vinte por cento) nos dias “zero” e “um”.
              <br />
              <br />
              3.5.1.3. A prioridade e visibilidade de agendamentos com antecedência em 03 (três)
              dias futuros (dia “3”) será destinada ao ENTREGADOR que tiver cumprido com o requisito
              da cláusula 3.5.1.2 e que tiver realizado, no mínimo, 10 (dez) entregas no domingo da
              semana anterior.
              <br />
              <br />
              3.5.1.4. A prioridade e visibilidade de agendamentos com antecedência em 04 (quatro)
              dias futuros será destinada ao ENTREGADOR que tiver cumprido com os requisitos das
              cláusulas 3.5.1.2 e 3.5.1.3 e que forem selecionados pelos estabelecimentos comerciais
              tomadores do serviço como seus favoritos.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
            style={{ padding: '1rem' }}
          >
            <Typography fontWeight="bold">4. Valores, Preço e Condições de Pagamento</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              4.1. O valor base do frete, entrega ou delivery será calculado pela MOOVERY de modo
              prévio, com base nas métricas de distância e tempo relacionadas à localização do
              estabelecimento comercial tomador do serviço e ao local de destino.
              <br />
              <br />
              4.1.1. O valor base do frete, entrega ou delivery poderá ser acrescido de uma tarifa
              dinâmica que leva em consideração, para além dos parâmetros indicados na cláusula 4.1,
              o congestionamento do trânsito no momento e os seus horários de pico ao longo do dia.
              <br />
              <br />
              4.1.2. Em casos excepcionais, a depender do pleito de negociação do estabelecimento
              comercial com a MOOVERY na intermediação, o valor poderá sofrer variações para mais ou
              para menos e o ENTREGADOR, desde já, declara estar ciente dessa possibilidade.
              <br />
              <br />
              4.1.3. Nos casos em que há necessidade de regresso do ENTREGADOR ao estabelecimento
              comercial tomador do serviço depois de efetuado o frete, entrega ou delivery, para
              devolução de troco do pagamento em dinheiro em espécie ou de máquina de pagamento de
              cartão, também haverá acréscimo de uma taxa de retorno ao valor base, também calculada
              com base nos parâmetros das cláusulas 4.1 e 4.1.1.
              <br />
              <br />
              4.2. O recebimento dos valores adimplidos pelo estabelecimento comercial tomador do
              serviço de frete, entrega ou delivery, para facilitação da gestão do aplicativo, será
              feito a MOOVERY, que no período de 01 (uma semana) fará o repasse do importe devido ao
              ENTREGADOR, o qual corresponde ao montante global pago pelo serviço e possível retorno
              ao estabelecimento comercial, reduzido da taxa de comissionamento de 20% (vinte por
              cento) devida à MOOVERY pela intermediação do negócio
              <br />
              <br />
              4.2.1. O ENTREGADOR, desde já, declara estar ciente do valor percentual de referida
              comissão e anui com a retenção antecipada para pagamento da MOOVERY pela
              intermediação.
              <br />
              <br />
              4.2.2. Em casos promocionais, após estudo de viabilidade de campanhas de publicidade,
              marketing e propaganda por parte da MOOVERY e depois de ser comunicada expressamente a
              promoção na PLATAFORMA, o ENTREGADOR poderá receber importe maior do que os 80%
              (oitenta por cento) habitualmente repassados pela MOOVERY, inclusive podendo atingir
              até os 100% (cem por cento) do valor pago pelo estabelecimento comercial tomador do
              serviço e não ocorrendo nenhuma retenção relativa à comissionamento da intermediação.{' '}
              <br />
              <br />
              4.3. O repasse semanal dos valores devidos ao ENTREGADOR será feito via transferência
              bancária destinada à conta indicada por ele no momento do cadastro.
              <br />
              <br />
              4.3.1. A MOOVERY não se responsabiliza pela falta ou atraso no repasse de valores em
              virtude de:
              <br />
              <br />
              a) Quaisquer problemas na conta bancária e/ou dados informados pelo ENTREGADOR;
              <br />
              <br />
              b) Falhas comprovadas no sistema de pagamento eletrônico disponibilizado pelas
              instituições financeiras ou bancárias;
              <br />
              <br />
              c) Caso fortuito e força maior;
              <br />
              <br />
              4.3.2. Quaisquer divergências entre o que a MOOVERY considera como devido ao
              ENTREGADOR e o que por ele é pleiteado, deverão ser analisadas no prazo de 5 (cinco)
              dias úteis após a comunicação da discordância com o montante, e, após a análise, caso
              o ENTREGADOR realmente tenha saldo a receber, o pagamento ocorrerá em até novos 5
              (cinco) dias úteis
              <br />
              <br />
              4.4. O ENTREGADOR, desde já, declara estar ciente de que os valores relativos a
              prejuízos e ressarcimentos decorrentes de dano ao produto, mercadoria ou alimento
              objeto do frete, entrega ou delivery, cobrados diretamente da MOOVERY pelos
              estabelecimentos comerciais tomadores do serviço, podem ser deduzidos do montante do
              repasse.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5bh-content"
            id="panel5bh-header"
            style={{ padding: '1rem' }}
          >
            <Typography fontWeight="bold">5. Obrigações do Entregador</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              5.1. São obrigações do ENTREGADOR:
              <br />
              <br /> a) Manter seus dados cadastrais e documentação devidamente atualizados junto à
              MOOVERY e comparecer pontualmente aos estabelecimentos comerciais tomadores de seu
              serviço e cumprir com os horários de agendamento e entrega acordados;
              <br />
              <br />
              b) Não fornecer seus dados de acesso à PLATAFORMA a ninguém e nem permitir que um
              terceiro se utilize de seu veículo motorizado ou bicicleta para prestação do frete,
              entrega ou delivery em seu lugar;
              <br />
              <br />
              c) Não utilizar, seja para proveito próprio ou de terceiros, quaisquer informações
              relacionadas à MOOVERY ou aos estabelecimentos comerciais tomadores de seu serviço e
              de seus clientes finais, ou qualquer informação que tenha acesso em virtude da
              presente autorização, razão pela qual se obriga a não armazenar nem tratar os dados
              pessoais obtidos, nem a entrar em contato com eles depois de haver entregado ou
              cancelado qualquer pedido;
              <br />
              <br />
              d) Abster-se de forçar, induzir ou convencer os estabelecimentos comerciais a cancelar
              o uso ou a negociar para atendimento fora da utilização da PLATAFORMA;
              <br />
              <br />
              e) Direcionar diretamente à MOOVERY toda e qualquer reclamação que tenha, pelo canal
              próprio de comunicação e suporte da PLATAFORMA, se abstendo de utilizar outros meios
              de informação para veicular suas insatisfações com a intenção de prejudicar a honra
              objetiva empresarial da MOOVERY ou dos estabelecimentos comerciais tomadores de seu
              serviço e às suas respectivas impressões no mercado e comércio, ou de caluniar,
              difamar ou injuriar seus respectivos colabores e empregados;
              <br />
              <br />
              f) Utilizar colete de segurança dotado de dispositivos retrorreflexivos, capacete,
              joelheiras e cotoveleiras, nos termos das disposições normativas do Contran;
              <br />
              <br />
              g) Não estar cumprindo nenhum tipo de pena de suspensão do direito de conduzir veículo
              automotor e não se encontrar com a Carteira Nacional de Habilitação cassada por causa
              de crime de trânsito, ou ainda estar impedido judicialmente de exercer os seus devidos
              direitos;
              <br />
              <br />
              h) Realizar semestralmente a verificação dos equipamentos obrigatórios e de segurança;
              <br />
              <br />
              i) Cumprir com os requisitos legais previstos na Lei nº 12.009/2009, na Resolução
              CONTRAN nº 356/2010 e na Lei Ordinária do Município de João Pessoa nº 10.611/2005;
              <br />
              <br />
              j) Responsabilizar-se integralmente por qualquer perda ou dano ao produto, mercadoria
              ou alimento que está sendo fretado, entregue ou transportado, inclusive em casos de
              atrasos, que por sua exclusiva culpa, venham a gerar o cancelamento da entrega, salvo
              se for gerado por caso fortuito ou força maior;
              <br />
              <br />
              k) Refazer o frete, entrega ou delivery quando houver erro seu na condução da
              encomenda, causando prejuízos aos usuários finais ou não;
              <br />
              <br />
              l) Retornar imediatamente ao estabelecimento comercial tomador do serviço após a sua
              prestação para devolução de troco do pagamento em dinheiro em espécie ou de máquina de
              pagamento de cartão;
              <br />
              <br />
              m) Manter sua motocicleta, bicicleta ou carro limpos, em bom estado de conservação, e
              com todos os acessórios necessários para uma pilotagem segura e responsável;
              <br />
              <br />
              n) Possuir seguro para que possa sanar eventuais prejuízos com sua motocicleta,
              bicicleta, carro e veículos de terceiros, causados por acidentes ou incidentes durante
              percurso de entrega;
              <br />
              <br />
              o) Não realizar qualquer entrega sob efeito de bebidas embriagantes, alucinógenos,
              narcóticos e demais substâncias que possam afetar seu estado psicológico normal;
              <br />
              <br />
              p) Contribuir com sistema de previdência social, na qualidade de autônomo, ficando,
              assim, a MOOVERY, isenta de toda e qualquer responsabilidade a respeito desta
              obrigação, assim como de consequências que possam surgir pela não realização desta
              atividade;
              <br />
              <br />
              q) Participar das capacitações e treinamentos ofertados, presencialmente e on-line,
              pela MOOVERY;
              <br />
              <br />
              r) Não reproduzir, modificar, explorar, publicar ou realizar qualquer outra ação com
              as marcas, logotipos, nomes e insígnias da MOOVERY ou dos estabelecimentos comerciais
              cadastrados e tomadores de seu serviço sem a prévia autorização expressa e por
              escrito;
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6bh-content"
            id="panel6bh-header"
            style={{ padding: '1rem' }}
          >
            <Typography fontWeight="bold">
              6. Tributação da Prestação do Serviço do Entregador
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              6.1. A responsabilidade pela emissão de nota fiscal e pagamento dos tributos
              incidentes sobre a prestação do serviço de frete, entrega ou delivery pelo ENTREGADOR,
              sobretudo a relativa ao ISS (Imposto sobre Serviços), de competência municipal, é de
              obrigação exclusiva dele e, sempre que solicitado pela MOOVERY, as suas devidas
              comprovações de adimplemento das obrigações tributárias, principais e acessórias,
              deverão ser fornecidas.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel7bh-content"
            id="panel7bh-header"
            style={{ padding: '1rem' }}
          >
            <Typography fontWeight="bold">7. Inexistência de Vínculo Trabalhista</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              7.1. Como profissional autônomo, livre e independente, o ENTREGADOR declara, desde já,
              que apesar de facilitar o encontro de estabelecimentos comerciais interessados em
              frete, entrega ou delivery, a PLATAFORMA não é essencial para o desenvolvimento da
              prestação de seu serviço, eis que sem a sua existência, ainda assim ele poderia
              continuar a prestá-lo, e que por isso, não há qualquer relação hierárquica, de
              dependência, com subordinação e de exclusividade entre ele e a MOOVERY.
              <br />
              <br />
              7.2. Em decorrência do presente TERMOS E CONDIÇÕES, sob qualquer hipótese ou em
              qualquer situação, não se presumirá a eventual existência, ou se estabelecerá a
              presunção de qualquer vínculo trabalhista, bem como quaisquer obrigações de caráter
              trabalhista e previdenciário entre as partes, por si, seus contratados, prepostos e/ou
              empregados, e não serão fiadoras das obrigações e encargos trabalhistas e sociais uma
              da outra, cabendo a cada parte a exclusividade e responsabilidade por tais obrigações,
              inclusive nas esferas civil e penal.
              <br />
              <br />
              7.3. Cada serviço de frete, entrega ou delivery é contratado diretamente entre o
              ENTREGADOR e o estabelecimento comercial cadastrado na PLATAFORMA de forma
              independente. Portanto, a MOOVERY não é parte do contrato de transporte celebrado e
              tampouco se responsabiliza por ele. A MOOVERY se limita a fornecer um ambiente virtual
              que permite a aproximação entre usuários e facilitar as transações e os pagamentos.
              <br />
              <br />
              7.4. O ENTREGADOR é responsável por suportar integralmente todas as condenações,
              custos e despesas relativas a processos administrativos e judiciais de qualquer
              natureza, que sejam eventualmente instaurados ou ajuizados contra a MOOVERY em
              consequência dos serviços de entrega por ele prestados e contratados através da
              PLATAFORMA, ainda que o litígio em questão ocorra após a extinção deste negócio
              jurídico.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel8bh-content"
            id="panel8bh-header"
            style={{ padding: '1rem' }}
          >
            <Typography fontWeight="bold">8. Inexistência de Garantias</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              8.1. As funcionalidades da PLATAFORMA são proporcionadas no estado em que se encontram
              atualmente e a MOOVERY não dá qualquer garantia quanto a elas. Em específico, não
              garante que:
              <br />
              <br />
              a) O funcionamento será ininterrupto, seguro ou isento de erros;
              <br />
              <br />
              b) O seu uso proporcione qualquer performance ou atenda a qualquer expectativa;
              <br />
              <br />
              c) O seu uso proporcione valores rentáveis mínimos à sobrevivência, ou manutenção do
              veículo utilizado nas entregas;
              <br />
              <br />
              8.2. Considerando que o ENTREGADOR é um profissional autônomo e os serviços prestados
              por ele na PLATAFORMA não possuem qualquer interferência da MOOVERY, ela não será, em
              nenhum momento, responsável por quaisquer consequências, prejuízos ou danos causados à
              motocicleta, bicicleta ou veículo.
              <br />
              <br />
              8.3. A MOOVERY não tem qualquer responsabilidade sobre qualquer comentário ou
              avaliação eventualmente disponibilizado na PLATAFORMA e, portanto, o ENTREGADOR desde
              já a isenta de quaisquer reclamações, danos ou prejuízos decorrentes desses conteúdos.
              <br />
              <br />
              8.4. A MOOVERY não será responsável pelos conteúdos, práticas e serviços ofertados em
              outros sites ou aplicativos móveis que não sejam de propriedade dela ou operados por
              ela, ainda que exista independentemente do motivo, link ou integração para eles na
              PLATAFORMA, eis que sua mera presença de não implica relação de sociedade, de
              supervisão, ou de solidariedade da MOOVERY para com esses sites, aplicativos móveis e
              seus conteúdos.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel9bh-content"
            id="panel9bh-header"
            style={{ padding: '1rem' }}
          >
            <Typography fontWeight="bold">9. Cessão Gratuita dos Direitos de Imagem</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              9.1. O ENTREGADOR, desde já, promove a cessão e transferência à MOOVERY dos direitos
              de uso de sua imagem, capturada enquanto realiza entregas, eventualmente usada em
              campanhas de publicidade, marketing e propaganda, ou em eventos produzidos e/ou
              patrocinados por ela, declarando que entende como imagem qualquer forma de
              representação, inclusive fotográfica, bem como o processo audiovisual que resulta da
              fixação de imagens com ou sem som, que tenha a finalidade de criar, por meio de sua
              reprodução, a impressão de movimento, independentemente dos processos de sua captação,
              do suporte usado inicial ou posteriormente para fixá-lo, bem como dos meios utilizados
              para sua veiculação.
              <br />
              <br />
              9.1.1. O ENTREGADOR, desde já, declara ainda que a presente cessão é feita em caráter
              universal, total e definitivo, por prazo indeterminado e a título gratuito, produzindo
              efeitos não só no Brasil, bem como no exterior.
              <br />
              <br />
              9.2. O ENTREGADOR, desde já, também autoriza a MOOVERY a utilizar a título gratuito ou
              oneroso a imagem cedida, no Brasil ou no exterior, sem qualquer limitação de tempo ou
              da modalidade de utilização, sem que caiba ao mesmo, qualquer participação no eventual
              proveito econômico que direta ou indiretamente ela venha a auferir, sendo que o
              presente ajuste produzirá efeitos inclusive em relação aos eventuais herdeiros e
              sucessores do ENTREGADOR.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel10bh-content"
            id="panel10bh-header"
            style={{ padding: '1rem' }}
          >
            <Typography fontWeight="bold">
              10. Inadimplemento das Obrigações e Exclusão da Plataforma
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              10.1. Os inadimplementos das obrigações ora estabelecidas nestes TERMOS E CONDIÇÕES
              são escalonados em infrações leves, graves e gravíssimas e estão sujeitos a diferentes
              sanções.
              <br />
              <br />
              10.2. São consideradas infrações leves, punidas com advertência, perdas de valores e
              agendamentos diários:
              <br />
              <br />
              a) Descumprimento das regras de conduta e vestimenta dos estabelecimentos comerciais
              ora cadastrados na PLATAFORMA e tomadores do serviço do ENTREGADOR;
              <br />
              <br />
              b) Após a conclusão de um frete, entrega ou delivery que exija o regresso do
              ENTREGADOR ao estabelecimento comercial tomador do serviço depois de prestado o
              serviço, para devolução de troco do pagamento em dinheiro em espécie ou de máquina de
              pagamento de cartão, a demora em mais de 01 (uma) hora;
              <br />
              <br />
              c) Não comparecimento ao estabelecimento comercial tomador de seu serviço autoagendado
              no horário determinado – compreende-se como não comparecimento o atraso por mais de 30
              (trinta) minutos contados do horário previsto;
              <br />
              <br />
              d) Após se agendar como fixo na MOOVERY mas com flexibilidade de estabelecimentos
              comerciais e não confirmar sua disponibilidade via chat ou atrasar sua chegada ao
              estabelecimento comercial selecionado por mais de 15 (quinze) minutos contados do
              horário previsto;
              <br />
              <br />
              10.3. São consideradas infrações graves, punidas com suspensão por tempo determinado,
              perdas de valores e agendamentos diários:
              <br />
              <br />
              a) Após a aceitação de um chamado de um estabelecimento comercial, o atraso em sua
              chegada ao local para coleta do produto, mercadoria ou alimento objeto do seu frete,
              entrega ou delivery em mais de 15 (quinze) minutos contados do horário previsto –
              Nesse caso, o pedido será realocado para outro ENTREGADOR, que fará jus a todo valor
              que seria repassado ao ENTREGADOR atrasado. Além disso, o primeiro atraso resultará
              numa suspensão de utilização da PLATAFORMA por 10 (dez) minutos; o segundo atraso
              resultará numa suspensão de utilização da PLATAFORMA por 20 (vinte) minutos; o
              terceiro atraso resultará numa suspensão de utilização da PLATAFORMA por 30 (trinta)
              minutos e o quarto atraso em diante resultará numa suspensão de utilização da
              PLATAFORMA por 01 (uma) hora;
              <br />
              <br />
              b) Após a coleta do produto, mercadoria ou alimento objeto do seu frete, entrega ou
              delivery no estabelecimento comercial, o atraso na conclusão da rota e prestação do
              serviço em mais de 15 (quinze) minutos contados do horário previsto - o primeiro
              atraso resultará numa suspensão de utilização da PLATAFORMA por 20 (vinte) minutos; o
              segundo atraso resultará numa suspensão de utilização da PLATAFORMA por 40 (quarenta)
              minutos e o terceiro atraso em diante resultará numa suspensão de utilização da
              PLATAFORMA por 01 (uma) hora;
              <br />
              <br />
              c) Após se agendar como fixo na MOOVERY mas com flexibilidade de estabelecimentos
              comerciais, solicitar a realocação de 03 (três) pedidos – Nesse caso, a suspensão será
              de 01 (um) dia;
              <br />
              <br />
              d) Após se agendar como fixo na MOOVERY mas com flexibilidade de estabelecimentos
              comerciais, aceitar pedido aleatório que impossibilite a prestação do serviço que a
              ele seria destinado na modalidade autoagendada – Nesse caso, a suspensão será de 01
              (um) dia;
              <br />
              <br />
              e) For reiteradamente mal avaliado pelos estabelecimentos comerciais ora cadastrados
              na PLATAFORMA, com a obtenção de nota média abaixo de 4,0 (quatro vírgula zero) –
              Nesse caso, a suspensão será de 01 (um) dia e o ENTREGADOR terá 03 (três) meses para
              alcançar desempenho melhor, sob pena de convolação em infração gravíssima;
              <br />
              <br />
              f) Cometer 05 (cinco) infrações leves – Nesse caso, a suspensão será de 01 (um) dia;
              <br />
              <br />
              10.3.1. A contagem de atrasos é zerada diariamente e os casos em que ele se deu por
              caso fortuito ou força maior, obviamente, não serão contabilizados.
              <br />
              <br />
              10.4. São consideradas infrações gravíssimas, punidas com exclusão da PLATAFORMA:
              <br />
              <br />
              a) Furtar ou extraviar o produto, mercadoria ou alimento objeto do seu frete, entrega
              ou delivery;
              <br />
              <br />
              b) Agredir fisicamente ou verbalmente funcionários ou colaboradores da MOOVERY e dos
              estabelecimentos comerciais tomadores de seu serviço, bem como os seus clientes
              finais;
              <br />
              <br />
              c) Emprestar, sublocar, fornecer ou compartilhar o acesso de sua conta para terceiros;
              <br />
              <br />
              d) Cometer 03 (três) infrações graves ou permanecer com nota média abaixo de 4,0
              (quatro vírgula zero) depois de passados os 03 (três) meses de prazo da infração “e”
              da cláusula 10.3;
              <br />
              <br />
              10.5. Todas as infrações serão devidamente comunicadas através da PLATAFORMA,
              acompanhada dos motivos de seu cometimento e, em caso de exclusão, referida decisão
              será comunicada com aviso prévio de 03 (três) dias úteis, com a preservação da
              privacidade e segurança de dados do ENTREGADOR durante o período em que prestou
              serviços intermediado pela PLATAFORMA;
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel11'}
          onChange={handleChange('panel11')}
          style={{ marginBottom: '1.5rem' }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel11bh-content"
            id="panel11bh-header"
            style={{ padding: '1rem' }}
          >
            <Typography fontWeight="bold">11. Disposições Gerais</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              11.1. A licença de uso da PLATAFORMA pelo ENTREGADOR possui tempo indeterminado,
              podendo apenas revogá-la nas hipóteses previstas na cláusula 10.4, em razão do
              cometimento de infração gravíssima. Já o ENTREGADOR pode a qualquer momento, por sua
              livre iniciativa, deixar de utilizar a PLATAFORMA, sem ocasionar nenhum prejuízo e nem
              precisar pagar qualquer multa.
              <br />
              <br />
              11.2. Estes TERMOS E CONDIÇÕES podem ser alterados a qualquer momento e suas
              modificações entrarão em vigência na data de publicação na PLATAFORMA. A continuidade
              de prestação de serviços de frete, entrega ou delivery pelo ENTREGADOR com a
              intermediação da MOOVERY resulta na automática aceitação da nova versão destas
              cláusulas.
              <br />
              <br />
              11.3. A mera tolerância da MOOVERY pelo inadimplemento de obrigações do ENTREGADOR não
              significará renúncia de quaisquer direitos, exceto se expressamente acordado desta
              maneira.
              <br />
              <br />
              11.4. Para dirimir questões oriundas deste contrato, fica eleito o foro da comarca de
              João Pessoa/PB.
              <br />
              <br />
              E, estando ciente das condições aqui dispostas, o ENTREGADOR declara, de sua livre e
              espontânea vontade, que concorda com os TERMOS E CONDIÇÕES e que irá cumprir com o
              avençado, expressando a sua concordância a partir da aceitação na PLATAFORMA.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Typography style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '1rem' }}>
          Atualizado em 12 de janeiro de 2022.
        </Typography>
      </div>
      <Footer />
    </>
  )
}
