import iconInstagram from '../../../assets/iconInstagram.svg'
import logoMoovery from '../../../assets/logoMoovery2.svg'
import iconYoutube from '../../../assets/iconYoutube.svg'
import iconSeta from '../../../assets/setaFooter.svg'

import TextFooter from '../../atoms/TextFooter'
import { useLocation } from 'react-router-dom'

import { Link } from 'react-router-dom'

import * as Styled from './styles'

export default function Footer() {
  function openInstagramMoovery() {
    window.open('https://www.instagram.com/moovery.app/')
  }

  let location = useLocation()

  return (
    <Styled.Content>
      <div className="container">
        <Styled.Header>
          <Styled.Infos>
            <img src={logoMoovery} alt="logoMoovery" />
            <Styled.Hr />
            <TextFooter>Telefone: (83)3113-6300</TextFooter>
            <TextFooter>contato@moovery.app</TextFooter>
          </Styled.Infos>
          <div className="socialMedia">
            <Styled.SocialGroup>
              <img
                src={iconInstagram}
                alt=""
                onClick={openInstagramMoovery}
                style={{ cursor: 'pointer' }}
              />
              <img src={iconYoutube} alt="" />
            </Styled.SocialGroup>
            <Styled.ButtonGroup>
              <Link to="/motoboy" style={{ textDecoration: 'none' }}>
                <Styled.Button>
                  Aplicativo para entregadores
                  <img src={iconSeta} alt="" />
                </Styled.Button>
              </Link>
              <Link to="/empresas" style={{ textDecoration: 'none', marginBottom: '1.5rem' }}>
                <Styled.Button>
                  Cadastrar empresa
                  <img src={iconSeta} alt="" />
                </Styled.Button>
              </Link>
              <Link to="/politica" style={{ textDecoration: 'none' }}>
                <TextFooter>
                  <strong>Política de privacidade</strong>
                </TextFooter>
              </Link>
              {location.pathname === '/motoboy' && (
                <Link to="/termos-entregador" style={{ textDecoration: 'none' }}>
                  <TextFooter>
                    <strong>Termos de uso entregador</strong>
                  </TextFooter>
                </Link>
              )}
              {location.pathname === '/empresas' && (
                <Link to="/termos-empresa" style={{ textDecoration: 'none' }}>
                  <TextFooter>
                    <strong>Termos de uso empresa</strong>
                  </TextFooter>
                </Link>
              )}
            </Styled.ButtonGroup>
          </div>
        </Styled.Header>
      </div>
    </Styled.Content>
  )
}
