import styled from 'styled-components'

export const Container = styled.div`
  padding: 20px;
  text-align: center;
`

export const Content = styled.div`
  margin: 0 auto;
  max-width: 800px;
  min-height: 600px;

  button {
    margin: 10px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }

  p {
    margin-top: 20px;
    font-size: 16px;
  }

  h2 {
    margin-bottom: 20px;
  }
`

export const Button = styled.button`
  margin: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? '#7241a1' : '#fff')};
  color: ${(props) => (props.selected ? '#fff' : '#000')};
  border: ${(props) => (props.selected ? 'none' : '1px solid #7241a1')};
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #7241a1;
    color: #fff;
  }
`
export const MainQuestion = styled.h1`
  color: #7241a1;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 20px;
`

export const SelectedCity = styled.h2`
  color: #f39c12;
  font-size: 1.5rem;
  margin-top: 20px;
  margin-bottom: 20px;
`
