import closeImg from '../../../assets/Close.svg'
import Modal from 'react-modal'
import { useState } from 'react'

import * as Styled from './styles'

Modal.setAppElement('#root')

export default function ModalConfirm({ nomeCliente, onClick }) {
  const [modalIsOpen, setIsOpen] = useState(true)

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        overlayClassName="react-modal-overlay"
        className="react-modal-content"
      >
        <button type="button" className="button-close" onClick={closeModal}>
          <img src={closeImg} alt="botao de fechar" />
        </button>

        <Styled.Content>
          <Styled.Title>
            Olá <Styled.Span>{nomeCliente}</Styled.Span>, deseja continuar seu cadastro?
          </Styled.Title>
          <div className="buttonsGroup">
            <Styled.ButtonOrange onClick={onClick}>Voltar para início</Styled.ButtonOrange>
            <Styled.ButtonOrange onClick={closeModal}>Continuar cadastro</Styled.ButtonOrange>
          </div>
        </Styled.Content>
      </Modal>
    </>
  )
}
