import Menu from '../../molecules/Menu'
import { Typography } from '@material-ui/core'
import Footer from '../../molecules/Footer'
import * as React from 'react'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import TitleBanner from '../../atoms/TitleBanner'

import * as Styled from './styles'

export default function PoliticaDePrivacidade() {
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <>
      <Menu />
      <Styled.InitialView>
        <div className="container">
          <div className="campoTexto">
            <TitleBanner>
              Termos de uso
              <br /> plataforma{' '}
            </TitleBanner>
            <div className="subTitle">João Pessoa/PB, 30 de agosto de 2022.</div>
          </div>
        </div>
      </Styled.InitialView>
      <div className="container">
        <Styled.Text>
          Pelo presente instrumento particular, doravante denominado de{' '}
          <strong>“TERMOS E CONDIÇÕES”</strong>, a{' '}
          <strong>MOOVERY SERVIÇOS DE INTERMEDIAÇÃO DE NEGÓCIOS LIMITADA</strong>, pessoa jurídica
          de direito privado inscrita no CNPJ sob o nº 34.778.892/0001-78, com sede estabelecida na
          rua Barão de Passagem, nº 1.534 do bairro da Torre, CEP 58.040-520, do município de João
          Pessoa/PB, , doravante denominada de <strong>“MOOVERY”</strong> e o{' '}
          <strong>“ESTABELECIMENTO”</strong>, toda e qualquer pessoa, natural ou jurídica,
          inlteressada em tomar serviço de frete, entrega ou delivery, qualificado de acordo com os
          seus dados informados no momento de seu cadastro na <strong>“PLATAFORMA”</strong>,
          resolvem ajustar as seguintes cláusulas e condições:
        </Styled.Text>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            style={{ padding: '1rem' }}
          >
            <Typography fontWeight="bold">1. Objeto</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              1.1. O objeto destes TERMOS E CONDIÇÕES é a regulamentação do cadastro, validação e
              uso da PLATAFORMA pelo ESTABELECIMENTO para que ele possa solicitar e tomar o serviço
              de frete, entrega ou delivery dos entregadores também ora cadastrados.
              <br />
              <br />
              1.1.1. O serviço prestado pela MOOVERY é apenas a intermediação da contratação entre o
              ESTABELECIMENTO e os entregadores parceiros, através da gestão da PLATAFORMA e que
              possui, para além de todas as cláusulas contidas aqui, maior regulamentação em
              contrato específico a ser formalizado entre as partes. Nessa perspectiva, o
              ESTABELECIMENTO reconhece que a MOOVERY não é uma transportadora e nem possui como
              objeto social a logística de bens, assim como que o serviço de frete, entrega ou
              delivery não é prestado por ela e sim pelos entregadores cadastrados que dela não são
              empregados, funcionários ou de qualquer maneira representante, eis que o prestam
              sempre de forma autônoma e independente.
              <br />
              <br />
              1.1.2. Entende-se por PLATAFORMA a solução digital criada pela MOOVERY e resultado do
              conjunto de softwares por ela desenvolvidos, apresentado no modelo de aplicativo móvel
              (para o sistema operacional Android), em componentes e páginas de Internet (baseado na
              Web) e/ou em programas para computadores (tanto para os sistemas operacionais Windows
              ou MacOS), bem como a sua codificação e APIs (Application Programming Interface –
              Interface de programação de aplicações), englobando todo o conteúdo dos arquivos
              fornecidos, seja através de um meio tangível, depositado em equipamentos de mídia
              física, através de downloads, ou por compartilhamento de armazenagem digital (nuvens
              virtuais), incluindo, mas não se limitando aos materiais escritos, explicativos e
              comentados, memorial descritivo, especificações funcionais-técnicas, código-fonte e
              scripts de seus upgrades, modificações, atualizações, adições e cópias.
              <br />
              <br />
              1.1.3. A PLATAFORMA, por enquanto, está disponível ao ESTABELECIMENTO tão somente em
              componentes e páginas (site) da Internet baseada na Web e não otimizadas para
              navegação mobile, mas apenas para computadores, através da hospedagem no servidor AWS
              (Amazon Web Services), cujo acesso será feito através de login e senha fornecidos após
              esta contratação.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} mb={5}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
            style={{ padding: '1rem' }}
          >
            <Typography fontWeight="bold">
              2. Requisitos, Documentação, Cadastro e Validação
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              2.1. O ESTABELECIMENTO que deseja solicitar e tomar o serviço de frete, entrega ou
              delivery através da intermediação da PLATAFORMA deve informar todos os seus dados
              necessários (caso pessoa física, 1) nome completo e nome fantasia do estabelecimento,
              2) números de inscrição no CPF e de RG, 3) número de celular principal, 4) endereço
              físico e de correspondência eletrônica – e-mail, 5) ) segmento comercial de atuação;
              caso pessoa jurídica, 1) nome empresarial e nome fantasia do estabelecimento, 2)
              número de inscrição no CNPJ, 3) número de contato business principal, 4) endereço da
              sede e de correspondência eletrônica – e-mail, 5) segmento comercial de atuação) à
              perfeita conclusão do cadastro e posterior validação.
              <br />
              <br />
              2.2. O ESTABELECIMENTO que deseja solicitar e tomar o serviço de frete, entrega ou
              delivery através da intermediação da PLATAFORMA deve possuir hardware (equipamentos e
              dispositivos) capaz de se conectar à Internet de modo não mobile (isto é, sem ser
              através de celular smartphone e sim com computador ou notebook através do wi-fi
              provido por um modem roteador ou até mesmo via cabo), bem como acesso à própria rede
              de Internet, cujas taxas, preços e encargos cobrados pelo provedor, obviamente, serão
              de sua própria responsabilidade.
              <br />
              <br />
              2.3. O ESTABELECIMENTO aqui declara estar ciente de que toda a documentação
              apresentada será analisada e aprovada previamente pela MOOVERY, que poderá ou não
              validar o seu cadastro, de acordo com os critérios internos de aprovação ora adotados.{' '}
              <br />
              <br />
              2.3.1. O ESTABELECIMENTO apenas poderá solicitar e tomar o serviço de frete, entrega
              ou delivery através da intermediação da PLATAFORMA após ter seu cadastro validado.{' '}
              <br />
              <br />
              2.3.2. O ESTABELECIMENTO se compromete com a veracidade e autenticidade de todos os
              documentos anexados na PLATAFORMA, assim como por todas as informações cadastrais, sob
              pena de responder civil e criminalmente.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
            style={{ padding: '1rem' }}
          >
            <Typography fontWeight="bold">
              3. Uso, Dados, Funcionamento e Características da Plataforma
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              3.1. O ESTABELECIMENTO declara estar ciente que através do aceite dos termos de uso da
              PLATAFORMA, lhe será concedida uma licença de utilização da plataforma dos moldes EULA
              – End User License Agreement, ou seja, um acordo de licença de usuário final em que
              não há, em nenhuma hipótese, a transmissão do direito de uso provisório do acesso do
              site obtido pelo ESTABELECIMENTO após a validação do cadastro a quaisquer terceiros,
              bem como não garante acesso, em circunstância alguma, à sua codificação, APIs,
              código-fonte e scripts de seus upgrades, modificações, atualizações, adições e cópias,
              mas apenas a utilização da PLATAFORMA para solicitar e tomar o serviço de frete,
              entrega ou delivery.
              <br />
              <br />
              3.1.1. A MOOVERY é a exclusiva titular dos direitos comerciais e de propriedade
              intelectual da PLATAFORMA, bem como de eventuais aprimoramentos, correções, traduções,
              alterações, novas versões e sistemas derivados, e de outros direitos a ele vinculados,
              garantidos pela legislação aplicável a marcas e patentes, direitos autorais, segredos
              comerciais e concorrência desleal. Portanto, não há qualquer aquisição desses direitos
              ao ESTABELECIMENTO.
              <br />
              <br />
              3.1.2. É vedado ao ESTABELECIMENTO ceder, doar, alugar, vender, arrendar, emprestar,
              dar em garantia, alienar, transferir, modificar, adaptar, descompilar, traduzir a
              PLATAFORMA, bem como reproduzi-la em obra derivada, realizar engenharia reversa para
              obtenção de seu código-fonte, disponibilizar o seu acesso de uso a terceiros não
              autorizados, seja fisicamente ou via on-line, inclusive por acesso remoto ou outra
              forma qualquer, bem como incorporá-la a outros programas ou sistemas não autorizados,
              sejam eles próprios ou de terceiros.
              <br />
              <br />
              3.1.3. As adaptações de melhoria desenvolvidas para PLATAFORMA, ainda que sugeridas
              pelo ESTABELECIMENTO, que resultem em adequação das funcionalidades ou criação de
              novas features e funções serão de propriedade intelectual exclusiva da MOOVERY. <br />
              <br />
              <br />
              3.1.3.1. O ESTABELECIMENTO não pode exigir da MOOVERY a customização/personalização da
              PLATAFORMA para atender uma necessidade específica dele, porém, a sugestão poderá ser
              aceita, em observância estrita à cláusula 3.1.3, após análise e decisão exclusiva da
              MOOVERY.
              <br />
              <br />
              3.1.4. Na hipótese de violação de quaisquer dos direitos de propriedade intelectual
              mencionados na cláusula 3.1.1, haverá a exclusão do ESTABELECIMENTO na PLATAFORMA e
              estes TERMOS E CONDIÇÕES e o próprio contrato de prestação de serviços de
              intermediação serão considerados imediatamente resolutos, sem depender de denúncia
              formal em juízo para extinguir o negócio jurídico, bastando, tão somente, uma
              notificação formal por todo e qualquer meio de comunicação que possam comprovar a
              ciência de seu teor, inclusive WhatsApp com a comprovação da leitura da mensagem
              instantânea enviada ou e-mail com rastreio de abertura da correspondência eletrônica,
              e o ESTABELECIMENTO deverá arcar com uma multa (cláusula penal) de R$ 1.500.000,00 (um
              milhão e quinhentos mil reais).
              <br />
              <br />
              3.2. Para perfeito uso da PLATAFORMA e solicitação do serviço de frete, entrega ou
              delivery aos entregadores é necessário que o ESTABELECIMENTO sempre esteja com o seu
              computador ou notebook ligado, conectado à Internet, com login efetuado no site.s{' '}
              <br />
              <br />
              3.2.1. A MOOVERY não se responsabiliza por mau funcionamento da PLATAFORMA decorrente
              da má qualidade da Internet contratada pelo ESTABELECIMENTO e todo e qualquer prejuízo
              ou dano relacionado a isso deverá ser tratado entre ele e seu provedor de rede <br />
              <br />
              3.3. Os entregadores parceiros concedem, em termos de uso próprio, a transmissão,
              disponibilização e/ou compartilhamento com o ESTABELECIMENTO dos seus dados cadastrais
              e os colhidos pelo aplicativo em seu celular, como, a título de exemplo, as suas
              geolocalizações fixas e em tempo real e as suas notas e comentários sobre seus
              serviços prestados na PLATAFORMA. Portanto, o ESTABELECIMENTO se obriga, para coletar,
              armazenar e tratar aquilo que foi transmitido, disponibilizado ou compartilhado em
              nome da perfeita intermediação da prestação do serviço de frete, entrega ou delivery
              solicitado, a criar política de privacidade e proteção de dados específica, ou, caso
              não crie a sua própria, ao menos a adotar a da MOOVERY, que deverá ser seguida por
              todos os seus sócios, administradores, funcionários e colaboradores, sob pena de
              incorrer em responsabilidade civil exclusiva, caso haja vazamento de sua parte.
              <br />
              <br />
              3.4. Os atendimentos das solicitações de tomada do serviço de frete, entrega ou
              delivery feitos pelo ESTABELECIMENTO são feitos exclusivamente pelos próprios
              entregadores parceiros, sem qualquer interferência da MOOVERY, que apenas garante o
              funcionamento da PLATAFORMA, seguindo diretrizes básicas de gestão, para ordenar a
              prestação de serviço de forma regular, uniforme, imparcial e meritocrática.
              <br />
              <br />
              3.5. Estão expressamente excluídos da possibilidade de serem, através do uso da
              PLATAFORMA, fretados ou entregues os seguintes bens ou coisas:
              <br />
              <br />
              a) Quaisquer documentos referentes a títulos de crédito (ex.: cheques, notas
              promissórias), vales-alimentação, cartões de crédito ou débito, pulseiras de pagamento
              por aproximação, smartwatches ou outros gadgets eletrônicos como (discos rígidos de
              armazenamento – HDs ou pendrives – Ipads ou Tablets), telefones celulares, notebooks,
              computadores;
              <br />
              <br />
              b) Joias, pérolas, pedras ou metais preciosos ou semipreciosos e suas ligas;
              <br />
              <br />
              c) Objetos de arte (ex.: quadros, esculturas);
              <br />
              <br />
              d) Cargas radioativas e/ou nucleares, material hospitalar ou que oferecem risco de
              contaminação bioquímica;
              <br />
              <br />
              e) Objetos ilícitos, como drogas, entorpecentes e armas, sejam elas brancas ou de
              fogo;
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
            style={{ padding: '1rem' }}
          >
            <Typography fontWeight="bold">4. Pagamento pelo Uso da Plataforma</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              4.1. O pagamento pelo uso da PLATAFORMA por parte do ESTABELECIMENTO é dividido em uma
              taxa de administração mensal e créditos por cada frete, entrega ou delivery solicitada
              <br />
              <br />
              4.1.1. O valor da taxa de administração mensal é de livre negociação entre as partes e
              será definido no contrato de prestação de serviços de intermediação.
              <br />
              <br />
              4.1.1.1. O inadimplemento da taxa de administração mensal resultará na exigibilidade
              da dívida, com a incidência de juros moratórios simples de 1% a.m. (um por cento ao
              mês) pro-rata die, multa de 2% (dois por cento) sobre o valor global e correção
              monetária através do IGP-M (Ìndice Geral de Preços de Mercado), inserção do nome do
              ESTABELECIMENTO nos cadastros restritivos de crédito e de adoção das medidas judiciais
              cabíveis, sobretudo, através do ajuizamento da execução cível do contrato de prestação
              de serviços de intermediação.
              <br />
              <br />
              4.1.1.2. O não uso da PLATAFORMA não acarreta automática o encerramento do vínculo
              jurídico existente entre as partes e as obrigações pactuadas aqui nestes TERMOS E
              CONDIÇÕES e no contrato de prestação de serviços de intermediação permanecem válidas e
              eficazes. Ou seja, a mera e simples não realização de solicitações de frete, entrega
              ou delivery por parte do ESTABELECIMENTO num determinado mês, de modo algum o exonera
              do pagamento da taxa de administração mensal, a menos que haja a comunicação formal de
              um pedido de suspensão do seu acesso à PLATAFORMA ou de resilição unilateral
              (desistência) da tomada deste serviço de intermediação através do contato de
              correspondência eletrônica: financeiro@moovery.app.
              <br />
              <br />
              4.1.2. O valor dos créditos é contratado e carregado na PLATAFORMA de forma prévia à
              solicitação dos fretes, entregas ou deliverys e, à medida que os serviços solicitados
              são prestados, tanto o valor da intermediação a que faz jus a MOOVERY, quanto o da
              remuneração do entregador parceiro são deduzidos automaticamente do saldo depositado.
              <br />
              <br />
              4.1.2.1. Em caso de solicitação de frete, entrega ou delivery por pelo ESTABELECIMENTO
              com saldo de créditos zerado e não recarregado, em prol da concretização da prestação
              do serviço e desde que autorizado pelo ESTABELECIMENTO expressamente no contrato de
              prestação de serviços de intermediação, a MOOVERY poderá conceder crédito especial
              suplementar para pagamento da contraprestação pecuniária devida a ela própria e ao
              entregador parceiro.
              <br />
              <br />
              4.1.2.2. Sobre o montante do crédito especial suplementar concedido incidirá, a partir
              do dia de sua concessão, juros compensatórios simples de 1% a.m. (um por cento ao mês)
              pro-rata die e o ESTABELECIMENTO deverá efetuar recarga que o quite e adicione saldo
              positivo ordinário em até 30 (trinta) dias, sob pena de caracterização da
              exigibilidade da dívida, com a incidência de juros moratórios simples de 1% a.m. (um
              por cento ao mês) pro-rata die, multa de 2% (dois por cento) sobre o valor global e
              correção monetária através do IGP-M (Ìndice Geral de Preços de Mercado), inserção do
              nome do ESTABELECIMENTO nos cadastros restritivos de crédito e de adoção das medidas
              judiciais cabíveis, sobretudo, através do ajuizamento da execução cível do contrato de
              prestação de serviços de intermediação.
              <br />
              <br />
              4.2. O valor de cada frete, entrega ou delivery é calculado de acordo com as métricas
              da tabela disponibilizada ao ESTABELECIMENTO no momento da contratação da prestação
              dos serviços de intermediação e variam de acordo com a distância e tempo relacionadas
              à localização do ESTABELECIMENTO e do local de destino, sendo o mínimo sempre R$ 6,00
              (seis reais).
              <br />
              <br />
              4.2.1. O valor base do frete, entrega ou delivery poderá ser acrescido de uma tarifa
              dinâmica – variável entre R$ 1,00 (um real) e R$ 10,00 (dez reais) – que leva em
              consideração, para além dos parâmetros indicados na cláusula 4.2, o congestionamento
              do trânsito no momento da solicitação e os seus horários de pico ao longo do dia, bem
              como a existência de datas comemorativas, feriados, disponibilidade de entregadores
              parceiros e os eventos climáticos que dificultem os seus respectivos deslocamentos.
              virtude de:
              <br />
              <br />
              4.2.2. Para além dos parâmetros de distância e tempo indicados na cláusula 4.2, a base
              de cálculo das métricas, pela volatilidade dos preços de mercado que afetam os
              entregadores, como o valor do combustível, pode ser alterada a qualquer momento e o
              ESTABELECIMENTO deve levá-las em consideração no momento das recargas do seu saldo,
              desde que sejam previamente cientificadas da alteração. <br />
              <br />
              <br />
              4.2.2.1. Essa informação poderá ser feita por todo e qualquer meio de comunicação que
              possam comprovar a ciência de seu teor, inclusive WhatsApp com a comprovação da
              leitura da mensagem instantânea enviada, e-mail com rastreio de abertura da
              correspondência eletrônica ou através de janela do chat da própria PLATAFORMA.
              <br />
              <br />
              4.2.2.2. A continuidade de solicitação pelo ESTABELECIMENTO da prestação de serviços
              de frete, entrega ou delivery com a intermediação da MOOVERY resulta na automática
              aceitação das novas métricas e valores das taxas de cada frete, entrega ou delivery.{' '}
              <br />
              <br />
              4.3. A recarga do saldo pode ser realizada através de boleto bancário, cartão de
              crédito/débito ou transferência via Pix.
              <br />
              <br />
              4.3.1. Caso a recarga do saldo ser realizada através de cartão de crédito/débito,
              desde já, o próprio ESTABELECIMENTO anui com a coleta dos dados do instrumento
              utilizado (número do cartão, nome do titular, data de validade etc) e com o tratamento
              descrito na política de privacidade da MOOVERY.
              <br />
              <br />
              4.4. O ESTABELECIMENTO, em razão de sua previsão de demanda de pedidos para um dia
              determinado, poderá solicitar a prestação do serviço de frete, entrega ou delivery
              para sua sede a um entregador parceiro exclusivo, que terá toda a autonomia e
              independência necessária para aceitar a solicitação ou não.
              <br />
              <br />
              4.4.1. Referida solicitação de exclusividade ensejará a cobrança de uma taxa
              extraordinária, para além das normalmente incidentes (administração mensal e créditos
              por frete, entrega ou delivery) de livre negociação entre as partes e que também será
              definido no contrato de prestação de serviços de intermediação.
              <br />
              <br />
              4.4.2. Referida solicitação de exclusividade deve ser feita com, no mínimo, 03 (três)
              dias antecipados da data desejada, para que a MOOVERY possa divulgar a solicitação na
              PLATAFORMA e os entregadores parceiros decidam atendê-las ou não.
              <br />
              <br />
              4.4.2.1. Caso não haja entregadores parceiros dispostos a realizar, de modo exclusivo,
              todos os serviços de frete, entrega ou delivery do ESTABELECIMENTO solicitante da
              exclusividade, a taxa extraordinária mencionada na cláusula 4.4.1 não será cobrada.
              <br />
              <br />
              4.5. A MOOVERY não possui qualquer poder de alocação dos entregadores parceiros, seja
              para dias comuns ou de exclusividade e não se responsabiliza pela (in)disponibilidade
              deles em qualquer momento, hora, turno ou dia.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5bh-content"
            id="panel5bh-header"
            style={{ padding: '1rem' }}
          >
            <Typography fontWeight="bold">5. Obrigações do Estabelecimento</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              5.1. São obrigações do ESTABELECIMENTO:
              <br />
              <br />
              a) Entregar os seus produtos, mercadorias ou alimentos aos entregadores parceiros, em
              embalagens adequadas e totalmente lacradas, junto com os acessórios necessários para o
              consumo, tais como talheres, guardanapos, molhos e condimentos correspondentes, caso
              solicitados pelo cliente final;
              <br />
              <br />
              b) Fornecer aos entregadores parceiros as informações necessárias dos produtos,
              mercadorias ou alimentos a serem fretadas ou entregues, como o preço global e o
              respectivo troco, se houver, no valor exato requerido pelo cliente final. Inclusive,
              não é de responsabilidade da MOOVERY, caso seja requerido pelo cliente final, valor
              diverso do que fora entregue/informado aos entregadores parceiros;
              <br />
              <br />
              c) Realizar o pagamento de cada frete, entrega ou delivery efetuados através da
              PLATAFORMA, conforme cláusula 4ª (quarta) destes TERMOS E CONDIÇÕES e do contrato de
              prestação de serviços de intermediação;
              <br />
              <br />
              d) Manter seus dados cadastrais e documentações devidamente atualizados junto à
              MOOVERY;;
              <br />
              <br />
              e) Não fornecer seus dados de acesso à PLATAFORMA a ninguém e nem permitir que um
              terceiro se utilize de seu login e senha para realizar qualquer tipo de solicitação;
              <br />
              <br />
              f) Não utilizar ou compartilhar, seja em proveito próprio não relacionado ao seu
              segmento comercial e ao frete, entrega ou delivery, ou para terceiros, quaisquer
              informações ou dados mencionados na cláusula 3.3 relacionadas à MOOVERY ou aos
              entregadores parceiros prestadores do serviço de frete, entrega ou delivery, ou de
              seus clientes finais;
              <br />
              <br />
              g) Abster-se de forçar, induzir ou convencer os entregadores parceiros a cancelar o
              uso ou a negociar a prestação dos serviços de frete, entrega ou delivery fora da
              utilização da PLATAFORMA;
              <br />
              <br />
              h) Direcionar diretamente à MOOVERY toda e qualquer reclamação que tenha, pelo canal
              próprio de comunicação e suporte da PLATAFORMA, se abstendo de utilizar outros meios
              de informação para veicular suas insatisfações com a intenção de prejudicar a honra
              objetiva empresarial da MOOVERY ou a dos entregadores parceiros prestadores do serviço
              e às suas respectivas impressões no mercado e comércio, ou de caluniar, difamar ou
              injuriar seus respectivos colaboradores e empregados; <br />
              <br />
              i) Participar das capacitações e treinamentos ofertados, presencialmente e on-line,
              pela MOOVERY;
              <br />
              <br />
              j) Não reproduzir, modificar, explorar, publicar ou realizar qualquer outra ação com
              as marcas, logotipos, nomes e insígnias da MOOVERY ou com a imagem dos entregadores
              parceiros prestadores do serviço de frete, entrega ou delivery sem a prévia
              autorização expressa e por escrito;
              <br />
              <br />
              k) Garantir a facilidade de estacionamento dos veículos dos entregadores parceiros à
              sua sede para busca do produto, mercadoria ou alimento a ser fretado ou entregue, bem
              como o livre acesso e uso das suas instalações sanitárias;
              <br />
              <br />
              l) Manter o seu hardware (equipamentos e dispositivos como o computador ou notebook, o
              modem roteador do wi-fi ou o cabo de rede de Internet) seguros, livre de invasões de
              vírus ou hackers, bem como fisicamente intactos, sem danos estruturais;
              <br />
              <br />
              m) Não solicitar o frete, entrega ou delivery dos bens e coisas listadas nas alíneas
              da cláusula 3.5;
              <br />
              <br />
              n) Obter todas as autorizações públicas necessárias para regularização do
              estabelecimento, como, por exemplo, os alvarás de funcionamento da prefeitura e do
              corpo de bombeiros local, licença da vigilância sanitária e outras mais específicas
              que o segmento comercial exija;
              <br />
              <br />
              o) Criar política de privacidade e proteção de dados específica, ou, caso não crie a
              sua própria, ao menos adotar a da MOOVERY, que deverá ser seguida por todos os seus
              sócios, administradores, funcionários e colaboradores, sob pena de incorrer em
              responsabilidade civil exclusiva, caso haja vazamento de sua parte;
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6bh-content"
            id="panel6bh-header"
            style={{ padding: '1rem' }}
          >
            <Typography fontWeight="bold">
              6. Inexistência de Vínculo Societário ou Trabalhista
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              6.1. Nem estes TERMOS E CONDIÇÕES e nem o contrato de prestação de serviços de
              intermediação geram entre as partes qualquer vínculo societário, sendo apenas um
              acordo comercial para prestação do serviço de intermediação na PLATAFORMA para
              contratação do serviço de frete, transporte ou delivery entre o ESTABELECIMENTO e o
              entregador parceiro, e também não guardam qualquer correlação com contrato de
              franquia, nem com gestão de negócios, nem com agenciamento, nem com representação
              comercial e nem com mandato.
              <br />
              <br />
              6.2. Em decorrência destes TERMOS E CONDIÇÕES e do contrato de prestação de serviços
              de intermediação, sob qualquer hipótese ou em qualquer situação, não se presumirá a
              eventual existência, ou se estabelecerá a presunção de qualquer vínculo trabalhista,
              bem como quaisquer obrigações de caráter trabalhista e previdenciário entre as partes,
              por si, seus contratados, prepostos e/ou empregados, que não serão responsáveis,
              solidariamente ou subsidiariamente pelas obrigações e encargos trabalhistas e sociais
              uma da outra, cabendo a cada parte a exclusividade e responsabilidade por tais
              obrigações, inclusive nas esferas civil e penal.
              <br />
              <br />
              6.3. O ESTABELECIMENTO é responsável por suportar integralmente todas as condenações,
              custos e despesas relativas a processos administrativos e judiciais de qualquer
              natureza, que sejam eventualmente instaurados ou ajuizados contra a MOOVERY em
              consequência dos serviços de entrega por ele contratados e tomados através da
              PLATAFORMA, ainda que o litígio em questão ocorra após a extinção deste negócio
              jurídico.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel7bh-content"
            id="panel7bh-header"
            style={{ padding: '1rem' }}
          >
            <Typography fontWeight="bold">7. Inexistência de Garantias</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              7.1. As funcionalidades da PLATAFORMA são proporcionadas no estado em que se encontram
              atualmente e a MOOVERY não dá qualquer garantia quanto a elas. Cada serviço de frete,
              entrega ou delivery é contratado diretamente entre o entregador parceiro e o
              ESTABELECIMENTO de forma independente. Portanto, a MOOVERY não é parte do contrato de
              transporte celebrado e tampouco se responsabiliza por ele. A MOOVERY se limita a
              fornecer um ambiente virtual que permite a aproximação entre usuários e facilitar as
              transações e os pagamentos. Em específico, não garante que:
              <br />
              <br />
              a) O funcionamento será ininterrupto, seguro ou isento de erros;
              <br />
              <br />
              b) O seu uso proporcione qualquer performance ou atenda a qualquer expectativa;
              <br />
              <br />
              c) O seu uso proporcione valores rentáveis mínimos de lucratividade do
              ESTABELECIMENTO;
              <br />
              <br />
              d) A prestação dos serviços de frete, entrega ou delivery pelos entregadores parceiros
              terão sempre as características de confiabilidade, pontualidade, qualidade e segurança
              (a despeito de que aqueles que repetidamente não cumpram com tais requisitos serão
              punidos e possivelmente excluídos da plataforma);
              <br />
              <br />
              7.2. Considerando que os entregadores parceiros são profissionais autônomos e os
              serviços de frete, entrega ou delivery prestados por ele na PLATAFORMA não possuem
              qualquer interferência da MOOVERY, ela não será, em nenhum momento, responsável por
              quaisquer consequências, prejuízos ou danos (morais, emergentes ou cessantes) causados
              por eles ao ESTABELECIMENTO, seja em sua sede física, seja nos produtos, mercadorias
              ou alimentos fretados ou entregues ou por atraso e extravio que ocasionem o
              cancelamento do pedido por parte do cliente final. Nessa situação, caberá à MOOVERY
              apenas a intermediação da resolução da problemática entre o entregador parceiro e o
              ESTABELECIMENTO.
              <br />
              <br />
              7.2.1. A MOOVERY também não é responsável pelos conteúdos, publicações ou práticas
              feitas pelos entregadores parceiros em suas redes sociais, digam elas respeito ou não
              ao ESTABELECIMENTO.
              <br />
              <br />
              7.2.2. A MOOVERY também não é responsável por falhas na PLATAFORMA quando relacionadas
              a interrupções ou suspensões da conexão de Internet provida, defeitos ou invasões de
              vírus nos computadores ou notebooks usados, mau funcionamento das instalações
              elétricas da sede do ESTABELECIMENTO ou por ausência de fornecimento de energia
              elétrica pela concessionária, má utilização ou incapacidade intelectual de acesso da
              PLATAFORMA pelos próprios colaboradores do ESTABELECIMENTO, bem como pela falta de
              conhecimento ou habilidade tecnológica deles para manuseio do hardware (equipamentos e
              dispositivos) necessários.
              <br />
              <br />
              7.3. A MOOVERY não é responsável pelos conteúdos, práticas e serviços ofertados em
              outros sites ou aplicativos móveis que não sejam de propriedade dela ou operados por
              ela, ainda que exista independentemente do motivo, link ou integração para eles na
              PLATAFORMA, eis que sua mera presença não implica relação de sociedade, de supervisão,
              ou de solidariedade da MOOVERY para com esses sites, aplicativos móveis e seus
              conteúdos.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel8bh-content"
            id="panel8bh-header"
            style={{ padding: '1rem' }}
          >
            <Typography fontWeight="bold">8. Cessão Gratuita dos Direitos de Imagem</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              8.1. O ESTABELECIMENTO, desde já, promove a cessão e transferência à MOOVERY dos
              direitos de uso de sua marca, insígnia, logotipo ou sinais distintivos, bem como das
              imagens de sua sede física, capturada enquanto os entregadores parceiros realizam a
              busca dos produtos, mercadorias ou alimentos a serem fretados ou entregues,
              eventualmente usada em campanhas de publicidade, marketing e propaganda, ou em eventos
              produzidos e/ou patrocinados por ela, declarando que entende como imagem qualquer
              forma de representação, inclusive fotográfica, bem como o processo audiovisual que
              resulta da fixação de imagens com ou sem som, que tenha a finalidade de criar, por
              meio de sua reprodução, a impressão de movimento, independentemente dos processos de
              sua captação, do suporte usado inicial ou posteriormente para fixá-lo, bem como dos
              meios utilizados para sua veiculação.
              <br />
              <br />
              8.1.1. O ESTABELECIMENTO, desde já, declara ainda que a presente cessão é feita em
              caráter universal, total e por prazo indeterminado, por enquanto durar o uso da
              PLATAFORMA pelo ESTABELECIMENTO, inclusive a título gratuito, produzindo efeitos não
              só no Brasil, bem como no exterior.
              <br />
              <br />
              8.2. O ESTABELECIMENTO, desde já, também autoriza a MOOVERY a utilizar a título
              gratuito ou oneroso a imagem cedida, no Brasil ou no exterior, por prazo
              indeterminado, por enquanto durar o uso da PLATAFORMA pelo ESTABELECIMENTO, sem
              qualquer limitação da modalidade de utilização, sem que caiba a ele, qualquer
              participação no eventual proveito econômico que direta ou indiretamente ela venha a
              auferir.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel9bh-content"
            id="panel9bh-header"
            style={{ padding: '1rem' }}
          >
            <Typography fontWeight="bold">
              9. Inadimplemento das Obrigações e Exclusão da Plataforma
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              9.1. O inadimplemento de quaisquer das cláusulas destes TERMOS E CONDIÇÕES cria, a
              favor da MOOVERY, a faculdade de excluir o ESTABELECIMENTO da PLATAFORMA e
              considerá-lo como resoluto.
              <br />
              <br />
              9.1.1. A despeito da faculdade mencionada acima, é causa de exclusão imediata da
              PLATAFORMA e de resolução deste negócio jurídico o descumprimento das obrigações
              contidas nos itens “e”, “f”, “g”, “j”, “m”, “n” e “o” da cláusula 5ª (quinta).
              <br />
              <br />
              9.2. A prática de agressão física ou verbal direcionada a entregadores parceiros ou
              aos funcionários e colaboradores administrativos da MOOVERY também é motivo de
              exclusão da PLATAFORMA e extinção contratual.
              <br />
              <br />
              9.3. A mudança de sede do ESTABELECIMENTO para região geográfica ainda não coberta
              pela MOOVERY também resulta na extinção do uso da PLATAFORMA e destes TERMOS E
              CONDIÇÕES.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel10bh-content"
            id="panel10bh-header"
            style={{ padding: '1rem' }}
          >
            <Typography fontWeight="bold">
              10. Vedação à Concorrência Desleal e ao Aliciamento
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              10.1. O ESTABELECIMENTO – seus sócios, representante legal e/ou administrador – se
              obrigam a, direta ou indiretamente (seja em seu próprio nome ou através de qualquer
              empresa que possua vínculo) em toda região geográfica de atuação da MOOVERY, por todo
              o uso da PLATAFORMA pelo ESTABELECIMENTO e, também, por mais 02 (dois) anos após o seu
              encerramento, a:
              <br />
              <br />
              a) Não desempenhar a mesma atividade empresarial da MOOVERY;
              <br />
              <br />
              b) Não aliciar, solicitar, incitar, desviar ou retirar qualquer cliente, fornecedor,
              distribuidor ou entregador parceiro – atuais ou futuros – da MOOVERY, nem de qualquer
              forma interferir no relacionamento mantido entre eles;
              <br />
              <br />
              c) Não solicitar ou encorajar qualquer potencial cliente, fornecedor, distribuidor ou
              entregador parceiro da MOOVERY a deixar de contratar com ela todo e qualquer tipo de
              negócio jurídico;
              <br />
              <br />
              d) Não solicitar ou encorajar qualquer pessoa a deixar seu emprego ou deixar de
              prestar serviços para a MOOVERY;
              <br />
              <br />
              e) Não aliciar qualquer dos atuais ou futuros empregados da MOOVERY;
              <br />
              <br />
              10.2. A mera tentativa de prática de referidas atitudes por parte do ESTABELECIMENTO,
              ainda que não concretizadas, por qualquer motivo, será considerada como violação
              expressa da cláusula 10.1
              <br />
              <br />
              10.3. Na hipótese de descumprimento das condições impostas pelas alíneas “a”, “b” e
              “c” da cláusula 10.1, o ESTABELECIMENTO deverá arcar com uma multa (cláusula penal) de
              R$ 500.000,00 (quinhentos mil reais), e na hipótese de descumprimento das condições
              impostas pelas alíneas “d” e “e” da cláusula 10.1, o ESTABELECIMENTO deverá arcar com
              uma multa de R$ 100.000,00 (cem mil reais).
              <br />
              <br />
              10.3.1. Nos cenários da cláusula 10.3, a resolução destes TERMOS E CONDIÇÕES com a
              exclusão do ESTABELECIMENTO na PLATAFORMA se tornará uma faculdade da MOOVERY, que não
              dependerá de denúncia formal em juízo para extinguir o negócio jurídico, bastando, tão
              somente, uma notificação formal por todo e qualquer meio de comunicação que possam
              comprovar a ciência de seu teor, inclusive WhatsApp com a comprovação da leitura da
              mensagem instantânea enviada ou e-mail com rastreio de abertura da correspondência
              eletrônica.
              <br />
              <br />
              10.4. O descumprimento de quaisquer das condições impostas, para além do
              reconhecimento de ato ilícito contratual por parte do ESTABELECIMENTO e da cominação
              da multa compensatória da cláusula 10.3, com a realização das atitudes descritas nas
              cláusulas 10.1, além da mera tentativa de suas práticas, vide redação da cláusula
              10.2, resultarão na responsabilização criminal dos responsáveis pelo ESTABELECIMENTO,
              em razão do cometimento dos “Crimes de Concorrência Desleal” contidos no art. 195 da
              Lei nº 9.279/1996.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel11'}
          onChange={handleChange('panel11')}
          style={{ marginBottom: '1.5rem' }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel11bh-content"
            id="panel11bh-header"
            style={{ padding: '1rem' }}
          >
            <Typography fontWeight="bold">11. Disposições Gerais</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              11.1. Estes TERMOS E CONDIÇÕES têm prazo indeterminado e gozarão de validade e
              eficácia por todo o tempo que o ESTABELECIMENTO utilizar a PLATAFORMA. A licença de
              uso da PLATAFORMA pelo ESTABELECIMENTO também possui prazo indeterminado, mas será
              revogada nas hipóteses previstas na cláusula 9ª (nona). Já o ESTABELECIMENTO pode a
              qualquer momento, por sua livre iniciativa, deixar de utilizar a PLATAFORMA, sem
              ocasionar nenhum prejuízo e nem precisar pagar qualquer multa, desde que haja a
              comunicação mencionada na cláusula 4.1.1.2.
              <br />
              <br />
              11.1.1. A MOOVERY se resguarda do direito de também resilir unilateralmente e
              imotivadamente, sem cominação de qualquer multa, desde que o ESTABELECIMENTO seja
              comunicado com 30 (trinta) dias de antecedência – período em que a intermediação
              através da PLATAFORMA e acesso do ESTABELECIMENTO para solicitação de frete, entrega
              ou delivery permanecerá vigente e em funcionamento – para garantir tempo hábil de
              contratação de entregadores próprios como empregados ou de busca outra plataforma de
              intermediação.
              <br />
              <br />
              11.2. A omissão ou tolerância por qualquer das partes em exigir o estrito cumprimento
              destes TERMOS E CONDIÇÕES não constituirá novação ou renúncia dos direitos aqui
              estabelecidos, mas apenas uma mera liberalidade que poderá ser revista e os direitos
              exercidos plena e integralmente a qualquer tempo.
              <br />
              <br />
              11.3. Se qualquer cláusula destes TERMOS E CONDIÇÕES for considerada nula ou vier a
              ser anulada por qualquer decisão jurisdicional transitada em julgado, tal nulidade ou
              anulabilidade não contaminará as demais cláusulas, que ainda terão plena validade e
              eficácia.
              <br />
              <br />
              11.4. As cláusulas destes TERMOS E CONDIÇÕES podem ser alteradas a qualquer momento e
              suas modificações entrarão em vigência na data de publicação na PLATAFORMA. A
              continuidade de solicitação pelo ESTABELECIMENTO da prestação de serviços de frete,
              entrega ou delivery com a intermediação da MOOVERY resulta na automática aceitação da
              nova versão destas cláusulas, em substituição às aqui redigidas.
              <br />
              <br />
              11.5. Para dirimir questões oriundas destes TERMOS E CONDIÇÕES, fica eleito o foro da
              comarca de João Pessoa/PB.
              <br />
              <br />
              E, estando ciente das condições aqui dispostas, o ESTABELECIMENTO declara, de sua
              livre e espontânea vontade, que concorda com os TERMOS E CONDIÇÕES e que irá cumprir
              com o avençado, expressando a sua concordância a partir da aceitação na PLATAFORMA
              logo em seu primeiro login.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Typography style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '1rem' }}>
          Atualizado em 30 de agosto de 2022.
        </Typography>
      </div>
      <Footer />
    </>
  )
}
