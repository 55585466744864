import React, { useEffect } from 'react'
import AlertWaiting from '../../molecules/AlertWaiting'
import AlertConfirm from '../../molecules/AlertConfirm'
import AlertError from '../../molecules/AlertError'
import ButtonOrange from '../../atoms/ButtonOrange'
import Modal from '../../molecules/ModalCadastro'
import TitleRoxo from '../../atoms/TitleRoxo'
import Footer from '../../molecules/Footer'
import Menu from '../../molecules/Menu'

import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useState } from 'react'

import { verificaLatitudeLongitude } from '../../../services/api'

import * as Styled from './styles'

export default function CadastroFinal() {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()
  const history = useHistory()

  const [errorCadastroFinal, setErrorCadastroFinal] = useState(false)
  const [errorCep, setErrorCep] = useState(false)
  const [waiting, setWaiting] = useState(false)
  const [confirm, setConfirm] = useState(false)

  const [disabled, setDisabled] = useState(false)

  const [abrirModalCadastro] = useState(sessionStorage.getItem('open'))
  const [nomeLocal] = useState(localStorage.getItem('nomeCliente'))
  const [cpfOuCnpj] = useState(localStorage.getItem('CPFOUCNPJ'))

  const [confirmacaoSenha, setConfirmacao] = useState('')
  const [validarSenha, setValidarSenha] = useState(false)

  const [resultLocation, setResultLocation] = useState('')
  const [logradouro, setLogradouro] = useState('')
  const [nomeEmpresa, setEmpresa] = useState('')
  const [cidade, setCidade] = useState('')
  const [bairro, setBairro] = useState('')
  const [estado, setEstado] = useState('')
  const [numero, setNumero] = useState('')
  const [senha, setSenha] = useState('')
  const [cep, setCep] = useState('')

  useEffect(() => {
    if (!cpfOuCnpj) {
      history.push('./cadastro')
    }
    // eslint-disable-next-line
  }, [])

  async function onSubmit() {
    setDisabled(true)
    if (senha !== confirmacaoSenha) {
      setValidarSenha(true)
      setTimeout(() => {
        setValidarSenha(false)
      }, 3000)
      return
    }

    const enviar = {
      cep: cep,
      rua: logradouro,
      cidade: cidade,
      bairro: bairro,
      estado: estado,
      numero: numero,
      senha: senha,
      nomeEmpresa: nomeEmpresa,
      cnpjOuCpf: cpfOuCnpj,
      latitude: resultLocation.latitude.toString(),
      longitude: resultLocation.longitude.toString()
    }

    setWaiting(true)
    const response = await fetch('https://moovery.app/api/empresa/cadastrar_empresa.php', {
      method: 'PUT',
      headers: { 'Content-type': 'x-www-form-urlencoded' },
      body: JSON.stringify(enviar)
    })

    if (response.status === 500) {
      setErrorCadastroFinal(true)
      setTimeout(() => {
        setDisabled(false)
        setErrorCadastroFinal(false)
      }, 5000)
    }

    const data = await response.json()
    setWaiting(false)

    if (!data.error && data.data.code === 201) {
      setConfirm(true)
      setTimeout(() => {
        setConfirm(false)
        history.push('./concluir-cadastro')
      }, 3000)
      localStorage.removeItem('CPFOUCNPJ')
      localStorage.removeItem('nomeCliente')
    }
  }

  async function getCep(value) {
    setCep(value)
    try {
      const response = await fetch(`https://viacep.com.br/ws/${value}/json/`)
      const data = await response.json()

      setLogradouro(data.logradouro)
      setCidade(data.localidade)
      setBairro(data.bairro)
      setEstado(data.uf)

      verificaLatitudeLongitude({ cep: value }).then(setResultLocation)
    } catch (error) {
      setErrorCep(true)
      setTimeout(() => {
        setErrorCep(false)
      }, 3000)
    }
  }

  function abrirTermosDeUso() {
    window.open(
      'https://termos-de-uso-empresa.s3.us-east-2.amazonaws.com/termos_de_uso_da_plataforma_estabelecimentos_v2.1.0.pdf'
    )
  }

  function removeStorage() {
    localStorage.removeItem('CPFOUCNPJ')
    localStorage.removeItem('nomeCliente')

    history.push('./cadastro')
  }

  return (
    <>
      <Menu />
      {!abrirModalCadastro && <Modal nomeCliente={nomeLocal} onClick={removeStorage} />}
      {confirm === true && <AlertConfirm />}
      {waiting === true && <AlertWaiting />}
      {errorCadastroFinal === true && (
        <AlertError>
          Ocorreu um erro inesperado <br /> entre em contato com o suporte
        </AlertError>
      )}
      {errorCep === true && <AlertError> Por favor insira um cep valido</AlertError>}
      <Styled.Container>
        <Styled.ImgWrapper></Styled.ImgWrapper>
        <Styled.Content>
          <Styled.Chamada>
            <TitleRoxo>Cadastre-se</TitleRoxo>
            <Styled.TitleDadoPessoal>
              <Styled.Subtitle>Ultimos passsos</Styled.Subtitle>
              <Styled.Hr />
            </Styled.TitleDadoPessoal>
            <Styled.InputGroupEmpresa>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <Styled.InputField>
                    <label htmlFor="cep">CEP</label>
                    <input
                      {...register('cep', { required: true })}
                      placeholder=""
                      onBlur={(event) => getCep(event.target.value)}
                    />
                    {cep === '' && errors.cep && (
                      <Styled.SpanError>
                        Preencha o campo ou verifique se está correto
                      </Styled.SpanError>
                    )}
                  </Styled.InputField>
                  <Styled.InputField>
                    <label htmlFor="bairro">Bairro</label>
                    <input
                      {...register('bairro')}
                      placeholder=""
                      onChange={(event) => {
                        setBairro(event.target.value)
                      }}
                      value={bairro}
                    />
                    {errors.cep && (
                      <Styled.SpanError>
                        Preencha o campo ou verifique se está correto
                      </Styled.SpanError>
                    )}
                  </Styled.InputField>
                  <Styled.InputField>
                    <label htmlFor="cidade">Cidade</label>
                    <input
                      {...register('cidade')}
                      placeholder=""
                      onChange={(event) => {
                        setCidade(event.target.value)
                      }}
                      value={cidade}
                    />
                    {errors.cep && (
                      <Styled.SpanError>
                        Preencha o campo ou verifique se está correto
                      </Styled.SpanError>
                    )}
                  </Styled.InputField>
                </div>
                <div className="row2">
                  <Styled.InputField>
                    <label htmlFor="rua">Rua</label>
                    <input
                      {...register('rua')}
                      value={logradouro}
                      onChange={(event) => setLogradouro(event.target.value)}
                      placeholder=""
                    />
                    {errors.rua && (
                      <Styled.SpanError>
                        Preencha o campo ou verifique se está correto
                      </Styled.SpanError>
                    )}
                  </Styled.InputField>
                  <Styled.InputField>
                    <label htmlFor="estado">Estado</label>
                    <input
                      {...register('estado')}
                      value={estado}
                      onChange={(event) => setEstado(event.target.value)}
                      placeholder=""
                    />
                    {estado === '' && errors.estado && (
                      <Styled.SpanError>
                        Preencha o campo ou verifique se está correto
                      </Styled.SpanError>
                    )}
                  </Styled.InputField>
                  <Styled.InputField>
                    <label htmlFor="numero">Numero</label>
                    <input
                      {...register('numero', { required: true })}
                      onChange={(event) => setNumero(event.target.value)}
                      placeholder=""
                    />
                    {errors.numero && (
                      <Styled.SpanError>
                        Preencha o campo ou verifique se está correto
                      </Styled.SpanError>
                    )}
                  </Styled.InputField>
                </div>
                <div className="row3">
                  <Styled.InputField>
                    <label htmlFor="nomeEmpresa">Nome da empresa</label>
                    <input
                      {...register('nome_empresa', { required: true })}
                      onChange={(event) => setEmpresa(event.target.value)}
                      placeholder=""
                    />
                    {errors.nome_empresa && (
                      <Styled.SpanError>
                        Preencha o campo ou verifique se está correto
                      </Styled.SpanError>
                    )}
                  </Styled.InputField>
                  <Styled.InputField>
                    <label htmlFor="senha">Senha</label>
                    <input
                      type="password"
                      {...register('senha', {
                        required: true,
                        minLength: {
                          value: 8,
                          message: 'A senha deve ter no mínimo 8 caracteres'
                        }
                      })}
                      onChange={(event) => setSenha(event.target.value)}
                      placeholder=""
                    />
                    {errors.senha && <Styled.SpanError>{errors.senha.message}</Styled.SpanError>}
                  </Styled.InputField>
                  <Styled.InputField>
                    <label htmlFor="confirmacao_senha">Confirme sua senha</label>
                    <input
                      type="password"
                      {...register('confirmacao_senha', {
                        required: true
                      })}
                      onChange={(event) => setConfirmacao(event.target.value)}
                      placeholder=""
                    />
                    {errors.confirmacao_senha && (
                      <Styled.SpanError>Campo obrigatorio*</Styled.SpanError>
                    )}
                    {validarSenha && (
                      <Styled.SpanError>
                        A senha de confirmação deve ser igual a senha
                      </Styled.SpanError>
                    )}
                  </Styled.InputField>
                </div>
                <p>
                  Ao clicar em Cadastre-se, você concorda com nossos{' '}
                  <Styled.A onClick={abrirTermosDeUso}>Termos de uso</Styled.A>.
                </p>
                <ButtonOrange type="submit" disabled={disabled}>
                  Cadastre-se
                </ButtonOrange>
              </form>
            </Styled.InputGroupEmpresa>
          </Styled.Chamada>
        </Styled.Content>
      </Styled.Container>
      <Footer />
    </>
  )
}
