import { GlobalStyle } from './styles/global'
// import Direcionador from './components/pages/Direcionador'
import Routes from './Routes'
import CookieConsent from 'react-cookie-consent'

import Cadastro from './components/pages/Direcionador'
export default function App() {
  return (
    <>
      <Routes>
        {/* <Direcionador /> */}
        <Cadastro />
      </Routes>
      <CookieConsent
        location="bottom"
        buttonText="Aceitar"
        cookieName="myAwesomeCookieName2"
        style={{
          background: '#2B373B',
          fontSize: '0.9rem',
          padding: '0.5rem',
          lineHeight: '1.5rem'
        }}
        buttonStyle={{
          color: 'var(--shape)',
          fontSize: '0.875',
          cursor: 'pointer',
          backgroundColor: 'var(--orange)',
          fontWeight: '700',
          padding: '0.5rem 0.75rem',
          borderRadius: '0.25rem'
        }}
        expires={150}
      >
        Este site utiliza cookies para te proporcionar uma melhor experiência. Ao continuar
        navegando, você aceita nossa
        <a
          href="/politica"
          style={{ textDecoration: 'none', color: 'white', fontWeight: '700', cursor: 'pointer' }}
        >
          {' '}
          Política de privacidade
        </a>
      </CookieConsent>
      <GlobalStyle />
    </>
  )
}
