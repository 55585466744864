import styled from 'styled-components'

export const InitialView = styled.div`
  height: 45vh;
  background-color: var(--purple);
  display: flex;
  align-items: center;

  .subTitle {
    font-size: 1rem;
    color: var(--shape);
    font-weight: 400;
    margin-top: 2rem;
  }
`

export const Text = styled.div`
  font-size: 1rem;
  color: var(--black);
  font-weight: 400;
  line-height: 1.75rem;
  margin: 2rem 0rem;
  text-align: justify;
`
