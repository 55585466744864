import ButtonOrange from '../../atoms/ButtonOrange'
import motoMoovery from '../../../assets/motoMoovery.png'
import * as Styled from './style'

export default function entreEmContato() {
  function openWhatsApp() {
    let mensagem = `Olá, gostaria de mais informações sobre a Moovery!`
    const params = new URLSearchParams({
      text: mensagem
    })

    window.open(`https://wa.me/558331136300?${params}`)
  }
  return (
    <>
      <Styled.BackgroundPurple>
        <Styled.ElementVantagensWhite>
          <img src={motoMoovery} alt="" className="motoMoovery" />
          <div className="descricaoGroupMoovery">
            <div className="titleGroupMoovery">
              <Styled.Title>Vem ser Moovery</Styled.Title>
              <Styled.SubTitle>Ficou com alguma dúvida?</Styled.SubTitle>
            </div>
            <p style={{ textAlign: 'center' }}>Entre em contato com a nossa equipe</p>
            <ButtonOrange link={openWhatsApp}>Fale conosco</ButtonOrange>
          </div>
        </Styled.ElementVantagensWhite>
      </Styled.BackgroundPurple>
    </>
  )
}
