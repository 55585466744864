import styled from 'styled-components'

export const Container = styled.div`
  background-color: var(--purple);
`

export const Content = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 768px) {
    width: 750px;
  }
  @media (min-width: 992px) {
    width: 970px;
  }
  @media (min-width: 1200px) {
    width: 1170px;
  }
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  @media (max-width: 1350px) {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
`

export const Chamada = styled.div`
  margin-top: 75px;
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  flex-direction: column;

  button {
    margin-bottom: 7.5rem;
  }
`

export const Span = styled.span`
  color: var(--orange);
`

export const ImgWrapper = styled.div`
  margin-top: 4rem;
  position: absolute;
  left: -7rem;

  img {
    @media (max-width: 1350px) {
      display: none;
    }
  }
`
