import styled from 'styled-components'

export const Content = styled.div`
  background-color: var(--purple);
`

export const Header = styled.header`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  height: auto;
  padding: 2rem 0;

  @media (max-width: 620px) {
    height: 400px;
  }
`

export const Infos = styled.header`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
`

export const Hr = styled.hr`
  width: 127px;
`

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const Button = styled.button`
  background-color: transparent;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border: none;
  gap: 1.75rem;

  color: var(--shape);
  font-weight: 400;
  font-size: 0.875rem;
`

export const SocialGroup = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
`

export const TitleFooter = styled.div`
  color: var(--shape);
  font-size: 0.875rem;
  font-weight: 700;
  word-wrap: break-word;
  cursor: pointer;
`
