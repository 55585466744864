import Computadores from '../../../assets/computadores.webp'
import iconSeta from '../../../assets/setaDireta.svg'

import TitleBanner from '../../atoms/TitleBanner'
import ButtonPage from '../../atoms/ButtonPage'
import Subtitle from '../../atoms/Subtitle'

import { Link } from 'react-router-dom'

import * as Styled from './styles'

export default function Empresas() {
  const local = localStorage.getItem('CPFOUCNPJ')

  return (
    <>
      <Styled.Container>
        <Styled.ImgWrapper>
          <img src={Computadores} alt="" />
        </Styled.ImgWrapper>
        <Styled.Content>
          <Styled.Chamada>
            <TitleBanner>
              Simplifique{' '}
              <Styled.Span>
                suas
                <br /> entregas
              </Styled.Span>{' '}
              com a<br /> Moovery e alcance{' '}
              <Styled.Span>
                mais
                <br /> clientes
              </Styled.Span>
            </TitleBanner>
            <Subtitle>Faça já seu cadastro</Subtitle>
            <Link to={local ? './cadastroFinal' : './cadastro'} style={{ textDecoration: 'none' }}>
              <ButtonPage>
                Cadastre-se
                <img src={iconSeta} alt="" />
              </ButtonPage>
            </Link>
          </Styled.Chamada>
        </Styled.Content>
      </Styled.Container>
    </>
  )
}
