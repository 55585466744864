import PoliticaDePrivacidade from '../components/pages/PoliticaDePrivacidade'
import OrganismoMotoboy from '../components/organisms/OrganismoMotoboy'
import OrganismoEmpresa from '../components/organisms/OrganismoEmpresa'
import TermosEntregador from '../components/pages/TermosEntregador'
import TermosEmpresa from '../components/pages/TermosPlataforma'
import CadastroFinal from '../components/pages/CadastroFinal'
import Cadastro from '../components/pages/Cadastro'
import ConcluirCadastro from '../components/pages/ConcluirCadastro'
import TrabalheConosco from '../components/pages/TrabalheConosco'
import CadastroSelect from '../components/pages/CadastroSelect'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

export default function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={OrganismoEmpresa} />
        <Route path="/empresas" component={OrganismoEmpresa} />
        <Route path="/motoboy" component={OrganismoMotoboy} />
        <Route path="/cadastro" component={CadastroSelect} />
        <Route path="/cadastro-empresa" component={Cadastro} />
        <Route path="/cadastroFinal" component={CadastroFinal} />
        <Route path="/politica" component={PoliticaDePrivacidade} />
        <Route path="/termos-entregador" component={TermosEntregador} />
        <Route path="/termos-empresa" component={TermosEmpresa} />
        <Route path="/concluir-cadastro" component={ConcluirCadastro} />
        <Route path="/trabalhe-conosco" component={TrabalheConosco} />
      </Switch>
    </Router>
  )
}
