import styled from 'styled-components'

export const ElementVantagensWhite = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 3rem;

  @media (min-width: 768px) {
    width: 750px;
  }

  @media (min-width: 992px) {
    width: 970px;
  }

  @media (min-width: 1200px) {
    width: 1170px;
  }

  .contentCards {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 2.5rem;
    justify-content: center;
    margin-top: 4rem;
  }

  .buttonDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
`

export const SubTitle = styled.div`
  font-size: 2.5rem;
  font-weight: 900;
  color: var(--purple);
  text-align: center;
  margin-top: 2rem;
`

export const BackgroundPurple = styled.div`
  background-color: #fff;
`
export const TitleCard = styled.div`
  font-size: 2.5rem;
  font-weight: 900;
  color: var(--purple);
  text-align: center;
`

export const CardContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2rem;

  h3 {
    color: var(--purple);
    font-weight: 900;
    font-size: 1.5rem;
    margin-top: 0.75rem;
  }

  p {
    color: var(--black);
    font-weight: 400;
    text-align: center;
  }
`
