import Aguardando from '../../../assets/aguardando.svg'
import TitleFooter from '../../atoms/TitleFooter'
import TextFooter from '../../atoms/TextFooter'
import * as Styled from './style'

export default function AlertAguardando() {
  return (
    <>
      <Styled.Container>
        <div className="content">
          <img src={Aguardando} alt="" />
          <div className="texto">
            <TextFooter>Aguarde...</TextFooter>
            <TitleFooter>Cadastro sendo verificado</TitleFooter>
          </div>
        </div>
      </Styled.Container>
    </>
  )
}
