import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

import * as Styled from './style'
import ButtonOrange from '../../atoms/ButtonOrange'
import { Link } from 'react-router-dom'

export default function ProcessoDeEntrega() {
  return (
    <>
      <Styled.BackgroundPurple>
        <Styled.ElementVantagensWhite>
          <Styled.SubTitle>Processo de entrega</Styled.SubTitle>
          <div className="contentCards">
            <Card sx={{ width: 250, height: 250 }}>
              <CardContent>
                <Styled.CardContent>
                  <div>
                    <Styled.TitleCard>1</Styled.TitleCard>
                    <h3>Abrir o painel</h3>
                  </div>
                  <p>Para iniciar o processo você vai abrir o seu painel de estabelecimento.</p>
                </Styled.CardContent>
              </CardContent>
            </Card>
            <Card sx={{ width: 250, height: 250 }}>
              <CardContent>
                <Styled.CardContent>
                  <div>
                    <Styled.TitleCard>2</Styled.TitleCard>
                    <h3>Cadastrar Entrega</h3>
                  </div>
                  <p>
                    No painel você irá cadastrar os dados de entrega, e ao cadastrar o entregador
                    mais próximo já será solicitado.
                  </p>
                </Styled.CardContent>
              </CardContent>
            </Card>
            <Card sx={{ width: 250, height: 250 }}>
              <CardContent>
                <Styled.CardContent>
                  <div>
                    <Styled.TitleCard>3</Styled.TitleCard>
                    <h3>Coleta do Produto</h3>
                  </div>
                  <p>
                    O entregador irá até o seu estabelecimento, informar o número do pedido e sairá
                    com ele para a entrega.
                  </p>
                </Styled.CardContent>
              </CardContent>
            </Card>
            <Card sx={{ width: 250, height: 250 }}>
              <CardContent>
                <Styled.CardContent>
                  <div>
                    <Styled.TitleCard>4</Styled.TitleCard>
                    <h3>Entrega</h3>
                  </div>
                  <p>
                    Quando o entregador sair para a entrega você poderá acompanhar todo o percuso
                    até o destino.
                  </p>
                </Styled.CardContent>
              </CardContent>
            </Card>
          </div>
          <div className="buttonDiv">
            <Link to={'./cadastro'} style={{ textDecoration: 'none' }}>
              <ButtonOrange>Cadastre-se agora!</ButtonOrange>
            </Link>
          </div>
        </Styled.ElementVantagensWhite>
      </Styled.BackgroundPurple>
    </>
  )
}
