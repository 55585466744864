import Menu from '../../molecules/Menu'
import { Typography } from '@material-ui/core'
import Footer from '../../molecules/Footer'
import * as React from 'react'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import TitleBanner from '../../atoms/TitleBanner'

import * as Styled from './styles'

export default function PoliticaDePrivacidade() {
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <>
      <Menu />
      <Styled.InitialView>
        <div className="container">
          <div className="campoTexto">
            <TitleBanner>
              Declaração de <br /> privacidade{' '}
            </TitleBanner>
            <div className="subTitle">João Pessoa/PB, 18 de julho de 2022.</div>
          </div>
        </div>
      </Styled.InitialView>
      <div className="container">
        <Styled.Text>
          Pelo presente instrumento particular, doravante denominado de{' '}
          <strong>“POLÍTICA DE PRIVACIDADE E DE TRATAMENTO DE DADOS”</strong>, a{' '}
          <strong>MOOVERY SERVIÇOS DE INTERMEDIAÇÃO DE NEGÓCIOS LTDA</strong>, pessoa jurídica de
          direito privado inscrita no CNPJ sob o nº 34.778.892/0001- 78, com sede estabelecida na
          rua Empresário João Rodrigues Alves, salas 1204 e 1205 do empresarial Delta Center, nº 125
          do bairro dos Bancários, CEP 58.051-022, do município de João Pessoa/PB, doravante
          denominada de <strong>“MOOVERY”</strong>, descreve, através dos termos mencionados abaixo,
          ao <strong>“ENTREGADOR”</strong>, também ao <strong>“ESTABELECIMENTO”</strong>, ambos
          qualificados de acordo com os dados e informações fornecidos no momento de seus
          respectivos cadastros na <strong>“PLATAFORMA”</strong>, bem como a quaisquer outros
          terceiros que de qualquer modo a acessem sem sequer fazer cadastro, quais os dados
          pessoais coletados, como eles são usados, armazenados e compartilhados, além de quais são
          os direitos que seus titulares possuem em relação a isso.
        </Styled.Text>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            style={{ padding: '1rem' }}
          >
            <Typography fontWeight="bold">1. Lista-conceito de Palavras-Chave</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <strong>1.1.</strong> Objetivando melhorar a compreensão e o entendimento desta{' '}
              <strong>POLÍTICA DE PRIVACIDADE E DE TRATAMENTO DE DADOS</strong> por qualquer leitor
              são adotadas as palavras-chave descritas abaixo na lista-conceito, que quando
              utilizadas no singular ou no plural, em letra maiúscula, minúscula, negritada,
              sublinhada, em itálico ou não, possuem especificamente o sentido particular que ora
              lhes é atribuído: <br />
              <br /> a) <strong>“ENTREGADOR”</strong> representa toda e qualquer pessoa, através de
              veículo motorizado ou não, interessada em prestar serviço de frete, entrega ou
              delivery, de forma autônoma;
              <br />
              <br /> b) <strong>“ESTABELECIMENTO”</strong> representa toda e qualquer pessoa,
              natural ou jurídica, interessada em tomar serviço de frete, entrega ou delivery;{' '}
              <br />
              <br /> c) <strong>“PLATAFORMA”</strong> representa o sítio eletrônico de domínio
              https://www.moovery.com.br, todos os seus subdomínios e abas, e os apps móveis para
              Android ou IOS que os <strong>ENTREGADORES</strong> podem utilizar, todos de
              propriedade intelectual da própria MOOVERY;
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} mb={5}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
            style={{ padding: '1rem' }}
          >
            <Typography fontWeight="bold">2. Tipos de Dados Coletados</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <strong>2.1.</strong> No momento da realização de cadastro, bem como por toda e
              qualquer navegação posterior na <strong>PLATAFORMA</strong>, com a contratação de
              qualquer serviço de intermediação, entrando em contato através dos canais de suporte e
              atendimento ou até mesmo apenas participando de pesquisas e promoções, vários tipos de
              dados podem e são coletados pela <strong>MOOVERY</strong>, através do seu
              compartilhamento pelo próprio titular ou por terceiros por ele autorizados, como:{' '}
              <br />
              <br /> a) Informações pessoais e de contato – para os <strong>ENTREGADORES</strong>:
              1) nome completo; 2) números de inscrição no CPF e de RG; 3) data de nascimento; 4)
              dados biométricos faciais; 5) número de telefone para contato; 6) endereço residencial
              e de correspondência eletrônica – e-mail; e 7) contato de emergência – e para os
              <strong> ESTABELECIMENTOS</strong>: caso pessoa física: 1) nome completo e nome
              fantasia do estabelecimento; 2) números de inscrição no CPF e de RG; 3) número de
              telefone para contato; 4) endereço da sede e de correspondência eletrônica – e-mail; e
              5) segmento comercial de atuação; caso pessoa jurídica, 1) nome empresarial e nome
              fantasia do estabelecimento; 2) número de inscrição no CNPJ; 3) número de contato
              business principal; 4) endereço da sede e de correspondência eletrônica – e-mail; e 5)
              segmento comercial de atuação;
              <br />
              <br />
              b) Informações do veículo utilizado para prestação do serviço de frete, entrega ou
              delivery, para os <strong>ENTREGADORES</strong>, exceto para o caso de uso de
              bicicleta: 1) Carteira Nacional de Habilitação (CNH) das categorias “A” ou “B”,
              sobretudo com autorização especial para exercício de atividade remunerada; 2)
              Certificado de Registro e Licenciamento do Veículo (CRLV ou CRLV-e) atualizado;
              <br />
              <br /> c) Informações financeiras e de pagamento – para os{' '}
              <strong>ENTREGADORES</strong>: 1) nº de conta bancária, agência, operação e banco
              utilizados – e para os <strong>ESTABELECIMENTOS</strong>: 1) nº de conta bancária,
              agência, operação e banco utilizados; 2) número de cartão de crédito ou débito, nome
              do titular do cartão, data de validade etc;
              <br />
              <br /> d) Informações decorrentes do uso e navegação na <strong>PLATAFORMA</strong>:
              1) nome de registro (login); 2) senha em formato criptografado; 3) perguntas de
              segurança para rememoração ou substituição da senha;
              <br />
              <br /> e) Informações técnicas sobre navegação e qual hardware utilizado – para os
              <strong>ENTREGADORES</strong>: 1) Especificações, tipo e versão operacional de sistema
              do modelo de telefone celular utilizado; 2) registro de endereço IP usado para
              conectá-lo à Internet; 3) localização geográfica fixa e também em tempo real; 4)
              informações de URL, de conexão de rede e do provedor; – e para os{' '}
              <strong>ESTABELECIMENTOS</strong>: 1) Especificações, tipo e versão operacional de
              sistema do modelo do equipamento computacional utilizado; 2) registro de endereço IP
              usado para conectá-lo à Internet; 3) localização geográfica fixa; 4) tipo e versão do
              navegador web utilizado; 5) informações de URL, de conexão de rede e do provedor;
              <br />
              <br /> f) Informações mercadológicas e de suporte: 1) Feedbacks, avaliações,
              comentários e notas pelos serviços prestados pelos <strong>ENTREGADORES</strong>; 2)
              Interações e conversas realizadas com os funcionários do chat de suporte da{' '}
              <strong>MOOVERY</strong>, tanto por parte dos <strong>ENTREGADORES</strong> quanto
              pelos<strong> ESTABELECIMENTOS</strong>;<br />
              <br /> g) Informações geradas em redes sociais de terceiros: 1) Conteúdo criado e
              publicado, em redes sociais de terceiros (Facebook, Instagram, TikTok, LinkedIn etc)
              em que há, de qualquer forma, menções ou interações com a MOOVERY ou que com ela seja
              compartilhado voluntariamente, como por exemplo, textos, comentários, artigos,
              fotografias, vídeos, stories ou outros conteúdos e mídias semelhantes; 2) Informações
              que já estejam disponíveis publicamente ou que foram tornadas públicas de modo
              espontâneo pelo seu titular;
              <br />
              <br /> 2.1.1. Para validação das informações fornecidas pelo{' '}
              <strong>ENTREGADOR</strong> e mencionadas nas alíneas “a” e “b”, são coletadas
              fotocópias de: 1) RG, CPF e/ou CNH; 2) Comprovante de residência; 3) CRLV ou CRLV-e;
              através de sua captura fotográfica feita diretamente no aplicativo móvel e pelo
              próprio telefone celular do <strong>ENTREGADOR</strong> ou por anexação do arquivo
              digital, caso já os possua em suas mídias;
              <br />
              <br /> 2.1.2. Além disso, para criação de seu perfil na <strong>PLATAFORMA</strong>, o
              <strong> ENTREGADOR</strong> também tem coletada uma selfie (autorretrato
              fotográfico), de modo que seja facilitada a sua validação e a sua identificação pelos
              <strong> ESTABELECIMENTOS</strong>;
              <br />
              <br /> 2.2. Informações demográficas e de interesses (exemplificativamente, dados
              demográficos, hábitos ou características de comportamento e rotina na Internet, faixa
              etária, gênero, segmento de atuação empresarial, nicho de clientes etc) e de como a
              <strong> PLATAFORMA</strong> é utilizada, quais são as interações cliente-site ou
              visitante-site, por exemplo, quais ações foram tomadas, quais links foram clicados,
              quais abas ou páginas foram abertas e por quanto tempo elas foram visualizadas)
              atualmente são coletadas pela <strong>MOOVERY</strong> através de cookies.
              <br />
              <br /> 2.2.1. Cookies são arquivos de texto colocados no computador para coletar
              automaticamente informações de logs padrão da Internet e informações de comportamento
              do visitante do site.
              <br />
              <br /> 2.2.2. O visitante ou cliente pode configurar manualmente seu navegador para
              notificá-lo quando receber um cookie. Isso permite que se faça uma decisão autônoma
              sobre aceitá-lo ou não. Como alternativa, é possível desativar todos os cookies nas
              configurações do navegador. No entanto, alguns dos serviços e recursos oferecidos pela
              <strong> MOOVERY</strong> através do site podem não funcionar corretamente se os
              cookies estiverem desativados.
              <br />
              <br /> 2.2.2.1. Os cookies podem ser cookies próprios ou de terceiros:
              <br />
              <br /> a) Cookies próprios – cookies que o site armazena em seu computador;
              <br />
              <br /> b) Cookies de terceiros – cookies armazenados no computador através do site,
              mas por terceiros, como o Google;
              <br />
              <br /> 2.2.2.2. Os cookies também podem ser de sessão, de desempenho, de
              funcionalidade, e de análise social:
              <br />
              <br /> a) Cookies de sessão são essenciais para permitir a navegação na{' '}
              <strong>PLATAFORMA</strong> e utilização de seus recursos. Sem esses cookies, os
              serviços solicitados não podem ser fornecidos. Eles são excluídos quando há o
              fechamento do navegador. Esses são cookies primários;
              <br />
              <br /> b) Cookies de desempenho coletam dados anônimos sobre como os visitantes e
              clientes usam o site da <strong>MOOVERY</strong>. Eles permitem reconhecer e contar o
              número de visitantes e clientes e ver como todos navegam e o usam, quando o estão
              usando e quais as regiões aproximadas de onde estão navegando. Esses são cookies
              primários;
              <br />
              <br /> c) Cookies de funcionalidade permitem que a <strong>PLATAFORMA</strong> se
              lembre das escolhas que o visitante ou cliente faz (como seu nome de usuário, idioma
              ou a região em que está) e fornece recursos aprimorados e mais pessoais. Esses cookies
              também podem ser usados para lembrar as alterações feitas no tamanho do texto, fontes
              e outras partes das páginas da web que são personalizáveis. As informações que esses
              cookies coletam podem ser anônimas e não podem rastrear atividades de navegação em
              outros sites. Eles são excluídos quando é feito o logout. Esses são cookies primários;
              <br />
              <br /> d) Cookies de análise social permite que a <strong>MOOVERY</strong> e empresas
              parceiras de anúncios forneçam informações mais relevantes para o visitante ou
              cliente, de acordo com seus interesses e eles também podem conectá-lo a redes de mídia
              social. Esses são cookies que podem ser primários ou de terceiros e que são mantidos
              em seu dispositivo até sua expiração ou exclusão manual;
              <br />
              <br /> 2.2.2.3. Ao iniciar a navegação no site da <strong>MOOVERY</strong>, uma
              mensagem pop-up será exibida notificando o visitante ou cliente de que cookies são
              utilizados e que ele pode aceitá-los integralmente, rejeitá-los integralmente ou
              configurar manualmente quais serão aceitos e quais serão rejeitados.
              <br />
              <br />
              2.3. Atualmente, a <strong>MOOVERY</strong> não coleta quaisquer informações definidas
              como dados sensíveis pela Lei Geral de Proteção de Dados (Lei nº 13.709/2018). Quando
              houver legitimo interesse ou for necessário coletar dados pessoais sensíveis, por
              qualquer motivo, o prévio, expresso e formal consentimento será solicitado para
              qualquer processamento que for voluntário (por exemplo, para finalidades de
              marketing). Em caso de coleta e processamento de dados pessoais sensíveis para outras
              finalidades, eles acontecerão apenas nas hipóteses de (i) detecção e prevenção de
              crime; e (ii) cumprimento da lei aplicável ou de ordem judicial.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
            style={{ padding: '1rem' }}
          >
            <Typography fontWeight="bold">3. Tratamento dos Dados Coletados</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              3.1. As finalidades para as quais a <strong>MOOVERY</strong> coleta os dados
              mencionados na cláusula 1.1 estão descritas a seguir:
              <br />
              <br /> a) As informações pessoais e de contato são destinadas à: 1) realização do
              cadastro e criação de perfil do <strong>ENTREGADOR</strong> e do{' '}
              <strong>ESTABELECIMENTO </strong>
              na <strong> PLATAFORMA</strong>, para que ambos possam ser facilmente identificados
              pelo outro quando solicitado o frete, entrega ou delivery, com a confirmação de que o
              <strong> ENTREGADOR</strong> X que atendeu à solicitação é aquele que realmente
              compareceu, de modo pessoal, ao <strong>ESTABELECIMENTO</strong> para prestar o
              serviço; 2) localização do <strong>ESTABELECIMENTO</strong> pelo{' '}
              <strong>ENTREGADOR</strong> para que se desloque corretamente e dele próprio pela
              <strong> MOOVERY</strong> e pelo <strong>ESTABELECIMENTO</strong> durante todo o seu
              percurso de busca e de frete, entrega ou delivery; 3) atendimento de solicitações e
              dúvidas, contato por telefone, e-mail, SMS, WhatsApp, ou outros meios de comunicação,
              inclusive para envio de notificações ou push quando há solicitação dos serviços; 4)
              envio de comunicações em geral; 5) marketing, prospecção, pesquisas de mercado, de
              opinião e promoção dos serviços, inclusive com viabilização de ofertas e envio de
              informações sobre produtos, serviços, novidades, funcionalidades, conteúdos e demais
              eventos relevantes; 6) exercício regular de direito, inclusive com a possibilidade de
              apresentação de documentos em processos judiciais e administrativos, se necessário,
              colaboração ou cumprimento de ordem judicial, de autoridade competente ou de órgão
              fiscalizador ou de obrigação legal ou regulatória; 7) legítimo interesse da{' '}
              <strong>MOOVERY</strong> de constante desenvolvimento de melhoria para a{' '}
              <strong>PLATAFORMA</strong> e do aperfeiçoamento de seu serviço de{' '}
              <strong>INTERMEDIAÇÃO</strong>, garantindo excelência tanto aos{' '}
              <strong>ENTREGADORES</strong> como aos <strong>ESTABELECIMENTOS</strong>;
              <br />
              <br /> b) As informações do veículo utilizado para prestação do serviço de frete,
              entrega ou delivery são destinadas à: 1) verificação do cumprimento das obrigações
              contratuais estabelecidas nos termos de uso do <strong>ENTREGADOR</strong>, bem como
              de sua observância à legislação aplicável, como a Lei nº 12.009/2009, a Resolução
              CONTRAN nº 356/2010 e a Lei Ordinária do Município de João Pessoa nº 10.611/2005; 2)
              exercício regular de direito, inclusive com a possibilidade de apresentação de
              documentos em processos judiciais e administrativos, se necessário, colaboração ou
              cumprimento de ordem judicial, de autoridade competente ou de órgão fiscalizador ou de
              obrigação legal ou regulatória;
              <br />
              <br />
              c) As informações financeiras e de pagamento são destinadas à: 1) facilitação das
              transações a serem realizadas entre a <strong>MOOVERY</strong>, os{' '}
              <strong>ENTREGADORES</strong> e os ESTABELECIMENTOS, como o repasse do pagamento feito
              pelo <strong>ESTABELECIMENTO</strong> pelo serviço de frete, entrega ou delivery
              prestado – depois de retida a comissão de intermediação devida – pelo{' '}
              <strong>ENTREGADOR</strong>; 2) utilização do sistema de recorrência para adimplemento
              da taxa de administração mensal e de suas recargas de crédito, automatizando o
              pagamento do <strong>ESTABELECIMENTO</strong>; 3) exercício regular de direito,
              inclusive com a possibilidade de apresentação de documentos em processos judiciais e
              administrativos, se necessário, colaboração ou cumprimento de ordem judicial, de
              autoridade competente ou de órgão fiscalizador ou de obrigação legal ou regulatória;
              <br />
              <br />
              d) As informações decorrentes do uso e navegação na <strong>PLATAFORMA</strong> são
              destinadas à: 1) prestação dos serviços contratados; 2) atendimento de solicitações e
              dúvidas, contato por telefone, e-mail, SMS, WhatsApp, ou outros meios de comunicação,
              inclusive para envio de notificações ou push quando há uso dos serviços; 3) exercício
              regular de direito, inclusive com a possibilidade de apresentação de documentos em
              processos judiciais e administrativos, se necessário, colaboração ou cumprimento de
              ordem judicial, de autoridade competente ou de órgão fiscalizador ou de obrigação
              legal ou regulatória; 4) legítimo interesse da <strong>MOOVERY</strong> de constante
              desenvolvimento de melhoria para a <strong>PLATAFORMA</strong> e do aperfeiçoamento de
              seu serviço de <strong>INTERMEDIAÇÃO</strong>, garantindo excelência tanto aos
              <strong> ENTREGADORES</strong> como aos <strong>ESTABELECIMENTOS</strong>;
              <br />
              <br />
              e) As informações técnicas sobre navegação e qual hardware utilizado são destinadas à:
              1) constante desenvolvimento de melhorias para <strong>PLATAFORMA</strong>, com a
              análise de seu funcionamento diário nos equipamentos dos <strong>ENTREGADORES</strong>{' '}
              e dos <strong>ESTABELECIMENTOS</strong> para verificação de possíveis erros ou bugs na
              sua programação; 2) aperfeiçoamento da prestação do serviço de frete, entrega ou
              delivery e de sua segurança, com a geração de relatórios administrativos e
              organizacionais; 3) identificação do posicionamento geográfico-base-inicial do
              <strong> ENTREGADOR</strong>, de suas preferências de horário e de rotas e de seu
              tempo médio de entrega; 4) recomendação de novos serviços ou funcionalidades; 5)
              geração de estatísticas, estudos, pesquisas e levantamentos pertinentes às atividades
              e comportamento no uso dos serviços; 6) exercício regular de direito, inclusive com a
              possibilidade de apresentação de documentos em processos judiciais e administrativos,
              se necessário, colaboração ou cumprimento de ordem judicial, de autoridade competente
              ou de órgão fiscalizador ou de obrigação legal ou regulatória; 7) legítimo interesse
              da <strong>MOOVERY</strong> de constante desenvolvimento de melhoria para a{' '}
              <strong>PLATAFORMA</strong>e do aperfeiçoamento de seu serviço de{' '}
              <strong>INTERMEDIAÇÃO</strong>, garantindo excelência tanto aos{' '}
              <strong>ENTREGADORES</strong> como aos <strong>ESTABELECIMENTOS</strong>;
              <br />
              <br />
              f) As informações mercadológicas e de suporte são destinadas à: 1) otimização da
              prestação do serviço de intermediação feito pela <strong>MOOVERY</strong>, com a
              análise métrica da pontualidade e qualidade dos <strong>ENTREGADORES</strong>, bem
              como de sua reputação e confiabilidade para verificação do cumprimento das obrigações
              contratuais estabelecidas nos termos de uso do <strong>ENTREGADOR</strong>; 2) da
              mesma forma, a gravação, transcrição ou oitiva das conversas no chat de suporte são
              destinadas também à finalidade descrita, mas se estendendo à verificação do
              cumprimento das obrigações contratuais estabelecidas nos termos de uso do
              <strong> ESTABELECIMENTO</strong>; 3) atendimento de solicitações e dúvidas, contato
              por telefone, e-mail, SMS, WhatsApp, ou outros meios de comunicação, inclusive para
              envio de notificações ou push quando há uso dos serviços; 4) prestação dos serviços
              contratados; 5) marketing, prospecção, pesquisas de mercado, de opinião e promoção dos
              produtos e serviços, inclusive com viabilização de ofertas e envio de informações
              sobre produtos, serviços, novidades, funcionalidades, conteúdos e demais eventos
              relevantes para a manutenção do relacionamento cliente-<strong>MOOVERY</strong>; 6)
              recomendação de novos serviços ou funcionalidades; 7) exibição de publicidade; 8)
              geração de estatísticas, estudos, pesquisas e levantamentos pertinentes às atividades
              e comportamento no uso dos produtos ou serviços; 9) constante desenvolvimento de
              melhorias para <strong>PLATAFORMA</strong>, com a análise de seu funcionamento diário
              para verificação de possíveis erros ou bugs na sua programação; 10) exercício regular
              de direito, inclusive com a possibilidade de apresentação de documentos em processos
              judiciais e administrativos, se necessário, colaboração ou cumprimento de ordem
              judicial, de autoridade competente ou de órgão fiscalizador ou de obrigação legal ou
              regulatória; 11) legítimo interesse da <strong>MOOVERY</strong> de constante
              desenvolvimento de melhoria para a <strong>PLATAFORMA</strong> e do aperfeiçoamento de
              seu serviço de <strong>INTERMEDIAÇÃO</strong>, garantindo excelência tanto aos
              <strong>ENTREGADORES</strong> como aos <strong>ESTABELECIMENTOS</strong>;
              <br />
              <br />
              g) As informações geradas em redes sociais de terceiros são destinadas à: 1)
              recompartilhamento pela MOOVERY de quaisquer desses conteúdos para quaisquer fins
              empresariais e comerciais lícitos, como a divulgação de seu serviço, concursos,
              premiações e outras promoções e engajamento institucional; 2) marketing, prospecção,
              pesquisas de mercado, de opinião e promoção dos produtos e serviços, inclusive com
              viabilização de ofertas e envio de informações sobre serviços, novidades,
              funcionalidades, conteúdos e demais eventos relevantes para a manutenção do
              relacionamento cliente-MOOVERY; 3) recomendação de novos serviços ou funcionalidades;
              4) exibição de publicidade; 5) geração de estatísticas, estudos, pesquisas e
              levantamentos pertinentes às atividades e comportamento no uso dos produtos ou
              serviços; 6) exercício regular de direito, inclusive com a possibilidade de
              apresentação de documentos em processos judiciais e administrativos, se necessário,
              colaboração ou cumprimento de ordem judicial, de autoridade competente ou de órgão
              fiscalizador ou de obrigação legal ou regulatória;
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
            style={{ padding: '1rem' }}
          >
            <Typography fontWeight="bold">4. Compartilhamento dos Dados Tratados</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              4.1. Para além do uso dos dados mencionados na cláusula 1.1 e 1.2 pela própria
              MOOVERY, eles poderão ser compartilhados com os seguintes tipos de terceiros: <br />
              <br />
              a) Provedores de serviços: Empresas externas e parceiras da MOOVERY que auxiliem a
              operação da intermediação;
              <br />
              <br />
              b) Fornecedores de serviços de tecnologia da informação;
              <br />
              <br />
              c) Empresas de atendimento ao consumidor e de comunicação;
              <br />
              <br />
              d) Provedores de serviços estatísticos, de pesquisas, de marketing e publicidade;
              <br />
              <br />
              e) Agências de análise de crédito e de serviços financeiros, de cobrança de dívida e
              escritório contábil e de advocacia contratado para assessoramento: Empresas externas
              que a MOOVERY pode utilizar para verificar a situação de crédito dos ESTABELECIMENTOS,
              bem como para cobrar pagamentos vencidos;
              <br />
              <br />
              f) Autoridades e órgãos reguladores;
              <br />
              <br />
              4.1.1. Os terceiros descritos nas alíneas “a”, “b”, “c”, “d” e ”e” só estão
              autorizados a acessar os dados para tarefas específicas, que forem requisitadas com
              base em instruções diretas das pessoas com poder de gerência da própria MOOVERY. Eles
              estão obrigados contratualmente a manter os dados compartilhados seguros,
              confidenciais e em sigilo, e nas hipóteses de vazamento respondem solidariamente pelo
              prejuízo causado.
              <br />
              <br />
              4.1.2. O compartilhamento com os terceiros descritos nas alíneas “a”, “b”, “c”, “d” e
              ”e” é destinado à: 1) prestação dos serviços contratados; 2) atendimento de
              solicitações e dúvidas, contato por telefone, e-mail, SMS, WhatsApp, ou outros meios
              de comunicação, inclusive para envio de notificações ou push quando há uso dos
              serviços; 3) envio de comunicações em geral; 4) marketing, prospecção, pesquisas de
              mercado, de opinião e promoção dos produtos e serviços, inclusive com viabilização de
              ofertas e envio de informações sobre serviços, novidades, funcionalidades,conteúdos e
              demais eventos relevantes para a manutenção do relacionamento cliente-MOOVERY; 5)
              geração de estatísticas, estudos, pesquisas e levantamentos pertinentes às atividades
              e comportamento no uso dos produtos ou serviços; 6) cobrança de dívidas; 7) constante
              desenvolvimento de melhorias para PLATAFORMA, com a análise de seu funcionamento
              diário para verificação de possíveis erros ou bugs na sua programação; 8) exercício
              regular de direito, inclusive com a possibilidade de apresentação de documentos em
              processos judiciais e administrativos, se necessário, colaboração ou cumprimento de
              ordem judicial, de autoridade competente ou de órgão fiscalizador ou de obrigação
              legal ou regulatória; 9) legítimo interesse da MOOVERY de constante desenvolvimento de
              melhoria para a PLATAFORMA e do aperfeiçoamento de seu serviço de INTERMEDIAÇÃO,
              garantindo excelência tanto aos ENTREGADORES como aos ESTABELECIMENTOS;
              <br />
              <br />
              4.1.3. O compartilhamento com autoridades e órgãos reguladores é destinado à: 1)
              investigações e adoção de medidas de prevenção e combate a ilícitos 2) exercício
              regular de direito, inclusive com a possibilidade de apresentação de documentos em
              processos judiciais e administrativos, se necessário, colaboração ou cumprimento de
              ordem judicial, de autoridade competente ou de órgão fiscalizador ou de obrigação
              legal ou regulatória;
              <br />
              <br />
              4.2. Caso a MOOVERY seja adquirida por, ou fundida com, outra empresa, incluindo por
              motivo de falência, o compartilhamento dos dados mencionados na cláusula 1.1 com os
              sucessores legais será feito em estrita observância às exigências da legislação
              vigente.
              <br />
              <br />
              4.3. A divulgação dos dados a terceiros também acontecerá para fazer cumprir os termos
              de qualquer contrato, acordo ou os termos de uso PLATAFORMA, conforme a legislação
              vigente.
              <br />
              <br />
              4.4. Mediante solicitação do titular do dado coletado, também será possível
              compartilhá-lo com qualquer terceiro que ele apontar como confiável.
              <br />
              <br />
              4.5. Ao usar a PLATAFORMA poderá existir a possibilidade de redirecionamento para
              outros apps ou sites de terceiros. Após o redirecionamento as práticas de privacidade
              serão regidas pelas políticas de privacidade e pelos termos de uso desses terceiros.
              Não é possível que a MOOVERY controle ou se responsabilize pelas práticas e conteúdo
              de privacidade deles. Portanto, sugere-se a atenta leitura das políticas de
              privacidade aplicáveis para entender como eles coletam e processam dados.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5bh-content"
            id="panel5bh-header"
            style={{ padding: '1rem' }}
          >
            <Typography fontWeight="bold">
              5. Armazenamento e Término do Tratamento dos Dados
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              5.1. Enquanto o titular dos dados for um ENTREGADOR ou ESTABELECIMENTO cadastrado na
              PLATAFORMA ou, ao menos, um em potencial, que tenha realizado submissão de seus dados
              para identificação, autenticação e validação, todas as informações coletadas serão
              mantidas em ambiente virtual seguro e controlado, armazenado na nuvem digital AWS
              (Amazon Web Services).
              <br />
              <br /> 5.2. De acordo com a legislação vigente, a MOOVERY poderá utilizar os dados
              mencionados na cláusula 2.1 e 2.2 por quanto tempo for necessário para satisfazer as
              finalidades para as quais eles foram coletados, vide cláusulas 3.1, 4.1.2 e 4.1.3 ou
              para cumprir com os requerimentos legais aplicáveis.
              <br />
              <br />
              5.3. Com a irretratável e irrevogável desistência do uso da PLATAFORMA, com o
              cancelamento e exclusão do cadastro, o armazenamento e tratamento dos dados
              mencionados nas cláusulas 2.1 e 2.2 poderão ser mantidos por um período adicional de
              tempo de 10 (dez) anos ou pelo exato prazo prescricional obrigatório, o que for maior,
              para fins de auditoria e cumprimento de obrigações legais ou regulatórias, para o
              exercício regular de direitos ou também pelo prazo necessário de acordo com a base
              legal que justifique a retenção dos dados, por exemplo, para preservação de direitos
              em caso de ajuizamento de ações judiciais em desfavor da MOOVERY. Ressalvadas essas
              hipóteses, o tratamento de dados se encerrará e eles serão eliminados no âmbito e
              limites tecnológicos possíveis.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6bh-content"
            id="panel6bh-header"
            style={{ padding: '1rem' }}
          >
            <Typography fontWeight="bold">6. Direitos dos Titulares dos Dados</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              6.1. Todos os titulares dos dados coletados têm direito de confirmar a existência,
              acessar, revisar, modificar e/ou requisitar uma cópia eletrônica da informação dos
              dados que estão sendo coletados pela MOOVERY e/ou compartilhados por ela com
              terceiros, bem como quais terceiros são esses.
              <br />
              <br /> 6.1.1. As informações pessoais e de contato podem ser alteradas ou retificadas
              a qualquer momento, na própria aba de menu do aplicativo, caso exista, ou enquanto não
              habilitada referida função, através do chat de suporte
              <br />
              <br /> 6.2. Caso o consentimento do titular do dado seja necessário para acessar ou
              usar determinado serviço, é possível solicitar à MOOVERY que esclareça se é possível
              prestar esse serviço sem o consentimento para o tratamento dos dados pessoais, ou
              quais são as consequências de não fornecer o consentimento para este caso.
              <br />
              <br /> 6.3. Caso qualquer dado pessoal seja tratado de forma desnecessária, em excesso
              para a finalidade a que se destina ou em desconformidade com a LGPD, é possível
              solicitar que a MOOVERY anonimize, bloqueie ou elimine esses dados, desde que fique
              efetivamente constatado o excesso, a falta de necessidade ou a desconformidade com a
              lei.
              <br />
              <br /> 6.4. Caso tenha sido dado o consentimento para tratamento de dados pessoais
              para finalidades específicas (e não necessárias para a prestação do serviço de
              intermediação), é possível solicitar a eliminação desses dados pessoais.
              <br />
              <br /> 6.5. A qualquer momento, é possível limitar o uso e divulgação, ou revogar o
              consentimento a qualquer uma das atividades de processamento dos dados coletados,
              salvo se elas forem destinadas a uma finalidade obrigatória para prestação do serviço
              de intermediação da MOOVERY ou se são situações imperativas e previstas na legislação
              vigente.
              <br />
              <br />
              6.6. Caso tenha sido dado o consentimento para tratamento de dados pessoais, é
              possível solicitar a revogação desta autorização. A revogação do consentimento pode
              resultar na impossibilidade de uso de algumas funcionalidades do site, ou até mesmo no
              encerramento dos serviços prestados, mas não impede o uso de (i) dados anonimizados; e
              (ii) dados cujo tratamento esteja baseado em outra hipótese legal prevista na LGPD.
              <br />
              <br /> 6.8. De acordo com a legislação vigente, esses direitos podem ser exercidos
              através dos canais de comunicação detalhados nesta POLÍTICA DE PRIVACIDADE E DE
              TRATAMENTO DE DADOS, sendo necessário o fornecimento de meios de identificação, como
              CPF/CNPJ, nome, RG, ou outros hábeis, a depender da sua necessidade.
              <br />
              <br />
              6.8.1. Os canais de comunicação são:
              <br />
              <br /> a) Chat de suporte do site;
              <br />
              <br /> b) E-mail: dpo@moovery.com;
              <br />
              <br /> 6.8.2. Sempre que um pedido for submetido sem o fornecimento das provas aptas a
              comprovar a legitimidade do titular dos dados ora requerente, o pleito será
              automaticamente rejeitado.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel7'}
          onChange={handleChange('panel7')}
          style={{ marginBottom: '2rem' }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel7bh-content"
            id="panel7bh-header"
            style={{ padding: '1rem' }}
          >
            <Typography fontWeight="bold">7. Aspectos Gerais da Coleta de Dados</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              7.1. Os dados podem ser acessados por colaboradores ou empregados da MOOVERY, desde
              que autorizados e que precisem ter acesso a referidas informações, em razão de sua
              função desempenhada na empresa. Todos eles estão obrigados a manter os dados acessados
              em estrita confidencialidade e sigilo, sob pena de configuração de justa causa para
              demissão e responsabilização civil pelos prejuízos causados.
              <br />
              <br /> 7.2. Os dados estão devidamente armazenados no servidor AWS (Amazon Web
              Services), referência mundial em segurança cibernética.
              <br />
              <br /> 7.2.1. Os dados pessoais coletados poderão ser transferidos para o exterior,
              por exemplo quando são armazenados pela MOOVERY em servidores de computação em nuvem
              localizados fora do Brasil, como é o caso do AWS. Para isso, a MOOVERY observa todos
              os requerimentos estabelecidos pela legislação vigente e adota as melhores práticas de
              segurança e privacidade para garantir a máxima integridade e confidencialidade dos
              seus dados pessoais.
              <br />
              <br /> 7.3. Esta POLÍTICA DE PRIVACIDADE E DADOS pode ser alterada a qualquer momento
              e suas modificações entrarão em vigência na data de publicação na PLATAFORMA. A
              continuidade de solicitação e prestação de serviços de frete, entrega ou delivery,
              feita respectivamente, pelos ESTABELECIMENTOS e pelos ENTREGADORES com a intermediação
              da MOOVERY resulta na automática aceitação da nova versão destas cláusulas.
              <br />
              <br /> 7.4. Ao ler esta POLÍTICA DE PRIVACIDADE E DE TRATAMENTO DE DADOS e clicar, ao
              final, em "Eu li, estou ciente das condições de tratamento dos meus dados pessoais e
              forneço meu consentimento, quando aplicável”, o titular dos dados consente com o
              tratamento das informações pessoais nas formas aqui indicadas.
              <br />
              <br /> 7.4.1. Nas hipóteses em que as alterações a esta POLÍTICA DE PRIVACIDADE E DE
              TRATAMENTO DE DADOS resultarem em mudanças nas práticas de tratamento de dados
              pessoais que dependam do consentimento do titular, será solicitado o consentimento com
              os novos termos, em relação ao tratamento de dados e finalidades indicados.
              <br />
              <br /> 7.5. Segundo a Lei nº 13.709/2018 (Lei Geral de Proteção de Dados), a MOOVERY é
              considerada como uma “Controladora” dos seus dados pessoais. Se após a leitura desta
              POLÍTICA DE PRIVACIDADE E DE TRATAMENTO DE DADOS ainda restar qualquer dúvida, ou por
              qualquer razão se torne necessária a comunicação para assuntos envolvendo dados
              pessoais, é possível entrar em contato pelos canais abaixo:
              <br />
              <br /> a) Chat de suporte do site;
              <br />
              <br /> b) E-mail: dpo@moovery.com;
              <br />
              <br /> 7.6. As informações pessoais e de contato, do veículo utilizado para prestação
              do serviço de frete, entrega ou delivery, financeiras e de pagamento podem ser
              alteradas ou retificadas a qualquer momento, na própria aba de menu da PLATAFORMA,
              caso exista, ou enquanto não habilitada referida função, através do chat de suporte.
              <br />
              <br />
              7.7. Para dirimir questões oriundas desta POLÍTICA DE PRIVACIDADE E TRATAMENTO DE
              DADOS, fica eleito o foro da comarca de João Pessoa/PB.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Typography style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '1rem' }}>
          Atualizado em 18 de julho de 2022.
        </Typography>
      </div>
      <Footer />
    </>
  )
}
