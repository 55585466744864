import styled from 'styled-components'

export const Content = styled.div`
  background-color: var(--purple);
`

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5rem;
`
export const Options = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3.75rem;

  @media (max-width: 668px) {
    display: none;
  }
`
