import styled from 'styled-components'

export const Button = styled.button`
  background: var(--orange);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0.75rem 2rem;

  color: var(--purple);
  font-weight: 700;
  font-size: 0.875rem;
  border-radius: 0.65rem;
`
