import styled from 'styled-components'

export const ButtonPage = styled.button`
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--shape);
  gap: 1.75rem;
  padding: 0.5rem 0.75rem;

  color: var(--shape);
  font-weight: 700;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  margin-top: 1rem;

  &:hover {
    background-color: #cc7a29;
    border: 1px solid #cc7a29;
    transition: 0.25s;
  }

  @media (max-width: 1018px) {
    margin-bottom: 2rem;
  }
`
